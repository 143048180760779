import React, { Component } from 'react';
import { Util } from 'helpers/Util';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const AdminTable = {
    state: null,
    http: null,
    injectState: function (parent, states, sortParams) {
        let state = {
            viewModel: null,
            endOfThePage: false,
            scrollInfo: 'Scroll down to load more',
            currentPage: states.params.currentPage ? parseFloat(states.params.currentPage) : parseFloat(sortParams.currentPage),
            searchString: states.params.searchString ? states.params.searchString : sortParams.searchString,
            sorter: states.params.sorter ? states.params.sorter : sortParams.sorter,
            isAscending: states.params.isAscending ? states.params.isAscending : sortParams.isAscending,
            itemsPerPage: states.params.itemsPerPage ? parseFloat(states.params.itemsPerPage) : parseFloat(sortParams.itemsPerPage),
            optionModalOpen: false,
            editModalOpen: false,
            editModalTitle: '',
            editModel: {},
            selected: null,
            selectMode: false,
            selectedItems: []
        };

        Object.keys(states).forEach(function (key) {
            state[key] = states[key];
        });

        if (parent.props.history)
            this.history = parent.props.history;

        return state;
    },
    init: function (parent) {
        this.state = parent.state;

        parent.searchKeyup = this.searchKeyup.bind(parent);
        parent.search = this.search.bind(parent);
        parent.sort = this.sort.bind(parent);
        parent.trClicked = this.trClicked.bind(parent);
        parent.load = this.load.bind(parent);
        parent.updateUrl = this.updateUrl.bind(parent);
        parent.closeOptionModal = this.closeOptionModal.bind(parent);
        parent.closeEditModal = this.closeEditModal.bind(parent);
        parent.edit = this.edit.bind(parent);
        parent.new = this.new.bind(parent);
        parent.modify = this.modify.bind(parent);
        parent.create = this.create.bind(parent);
        parent.delete = this.delete.bind(parent);
        parent.get = this.get.bind(parent);
        parent.modify = this.modify.bind(parent);
        parent.create = this.create.bind(parent);
        parent.scroll = this.scroll.bind(parent);
        parent.toggleSelectMode = this.toggleSelectMode.bind(parent);
        parent.selectAll = this.selectAll.bind(parent);
        parent.bulkDelete = this.bulkDelete.bind(parent);
    },
    bulkDelete: async function () {
        this.util.showModal("Bulk Delete", "Are you sure?", async () => {
            let ids = this.state.selectedItems.map(m => m.dataset.id);
            let res = await this.http.deleteData({ ids: ids }, '/api/user');
            if (res != null) {
                if (res.success) {
                    let deleted = [...this.state.viewModel];
                    ids.forEach((id) => {
                        deleted.splice(deleted.findIndex(m => m.ID == id), 1);
                    })
                    await this.util.successNotification(res.msg);
                    await this.setState({ viewModel: deleted });
                }
                else
                    this.util.errorNotification(res.msg);
            }
            this.util.closeModal();
        }, () => this.util.closeModal());
    },
    selectAll: async function (event) {
        let card = event.currentTarget.closest('.table-card');
        let tr = card.querySelectorAll('.table-content tr');
        let arr = [];
        tr.forEach((el) => {
            el.classList.add('selected');
            arr.push(el);
        });
        await this.setState({ selectedItems: arr });
    },
    toggleSelectMode: async function () {
        await this.setState({ selectMode: !this.state.selectMode });
        if (!this.state.selectMode) {
            this.state.selectedItems.forEach((selected) => {
                selected.classList.remove('selected');
            });
            await this.setState({ selectedItems: [] });
        }
        this.util.infoNotification('Select mode is ' + (this.state.selectMode ? 'on' : 'off'));
    },
    searchKeyup: async function (event) {
        if (event.keyCode == 13)
            await this.search();
    },
    search: async function () {
        await this.setState({
            currentPage: 1,
            endOfThePage: false,
            searchString: document.getElementById('searchString').value,
            scrollInfo: 'Scroll down to load more'
        })
        await this.load(true);
    },
    sort: async function (event) {
        event.preventDefault();
        var el = event.currentTarget;
        var data = el.dataset.sorter;
        await this.setState({
            sorter: data ? data : el.innerHTML.replace(' ', ''),
            isAscending: !this.state.isAscending,
            currentPage: 1,
            endOfThePage: false
        })
        await this.load(true);
    },
    trClicked: function (event) {
        if (!this.state.selectMode)
            this.setState({ optionModalOpen: true, selected: event.currentTarget.dataset.id });
        else {
            let existingIndex = this.state.selectedItems.findIndex(m => m == event.currentTarget);
            let selected = [...this.state.selectedItems];
            if (existingIndex >= 0) {
                event.currentTarget.classList.remove('selected');
                selected.splice(existingIndex, 1);
            }
            else {
                event.currentTarget.classList.add('selected');
                selected.push(event.currentTarget);
            }

            this.setState({ selectedItems: selected });
        }

    },
    closeOptionModal: function () {
        let element = document.getElementById('option-modal');
        Util.animate(element, 'animate__fadeOut', function () {
            this.setState({ optionModalOpen: false, selected: null });
        }.bind(this), 400);
    },
    closeEditModal: function () {
        let element = document.getElementById('edit-modal');
        Util.animate(element, 'animate__fadeOut', function () {
            this.setState({ editModalOpen: false });
        }.bind(this), 400);
    },
    scroll: async function (event) {
        //Sticky header
        var th = event.currentTarget.querySelectorAll('thead th');
        th.forEach((child) => {
            child.style.transform = 'translateY(' + (event.currentTarget.scrollTop - 1) + 'px)';
        });

        if (!this.state.endOfThePage && !this.state.loading) {
            let element = event.currentTarget;
            let scrollTop = element.scrollTop;
            let windowHeight = element.offsetHeight;
            let totalHeight = element.scrollHeight - 1;

            if (scrollTop + windowHeight >= totalHeight) {
                await this.load();
            }
        }
    },
    edit: async function () {
        await this.get();
        await this.setState({ editModalTitle: 'Edit', editModalOpen: true });
    },
    new: async function () {
        await this.setState({ editModalTitle: 'New', editModalOpen: true, editModel: {} });
    },
    get: async function () {
        let res = await this.http.getData({ id: this.state.selected }, '/api/user');
        if (res != null) {
            if (res.success)
                await this.setState({ editModel: res.data });
            else
                this.util.errorNotification(res.msg);
        }
    },
    modify: async function (event) {
        event.preventDefault();
        let res = await this.http.putData(null, this.state.editModel, '/api/user');
        if (res != null) {
            if (res.success) {
                let vm = [...this.state.viewModel];
                vm.splice(vm.findIndex((m) => m.ID == res.data.ID), 1, res.data);
                await this.setState({ viewModel: vm });
                this.util.successNotification(res.msg);
                this.closeEditModal();
            }
            else {
                this.util.errorNotification(res.msg);
            }
        }
    },
    create: async function (event) {
        event.preventDefault();
        let res = await this.http.postData(null, this.state.editModel, '/api/user');
        if (res != null) {
            if (res.success) {
                let vm = [...this.state.viewModel];
                vm.splice(0, 0, res.data);
                await this.setState({ viewModel: vm });
                this.util.successNotification(res.msg);
                this.closeEditModal();
            }
            else {
                this.util.errorNotification(res.msg);
            }
        }
    },
    delete: async function () {
        this.util.showModal("Delete The Entry", "Are you sure?", async () => {
            let res = await this.http.deleteData({ ids: [this.state.selected] }, '/api/user');
            if (res != null) {
                if (res.success) {
                    let vm = [...this.state.viewModel];
                    vm.splice(vm.findIndex((m) => m.ID == this.state.selected), 1);
                    await this.setState({ viewModel: vm });
                    this.util.successNotification(res.msg);
                    this.closeOptionModal();
                } else
                    this.errorNotification(res.msg);

                this.util.closeModal();
            }
        }, () => {this.util.closeModal()})
    },
    load: async function(isReset, isInit) {
        var params = this.state.params.parent ? { parent: this.state.params.parent, sorter: this.state.sorter, isAscending: this.state.isAscending, searchString: this.state.searchString, currentPage: this.state.currentPage, itemsPerPage: this.state.itemsPerPage }
            : { sorter: this.state.sorter, isAscending: this.state.isAscending, searchString: this.state.searchString, currentPage: this.state.currentPage, itemsPerPage: this.state.itemsPerPage };

        await this.setState({ loading: true });
        let res = await this.http.getData(params, this.state.loadUrl);
        if (res != null) {
            if (res.data != null && res.data.length > 0) {
                await this.setState({ currentPage: this.state.currentPage + 1 });
                if (this.state.viewModel == null || isReset)
                    await this.setState({ viewModel: res.data });
                else
                    await this.setState({ viewModel: this.state.viewModel.concat(res.data) })

                if(res.data.length < this.state.itemsPerPage)
                    await this.setState({ scrollInfo: 'You have reached the end of the entries.', endOfThePage: true });
            } else {
                await this.setState({ scrollInfo: 'You have reached the end of the entries.', endOfThePage: true });
            }

            if (!isInit)
                this.updateUrl();
        }
        await this.setState({ loading: false });
    },
    updateUrl: function () {
        this.props.history.replace(this.props.location.pathname + '?' + Util.constructUrlParams({
            currentPage: 1,
            searchString: document.getElementById('searchString').value,
            sorter: this.state.sorter,
            isAscending: this.state.isAscending,
            itemsPerPage: this.state.itemsPerPage,
        }));
    }
}

export class TableHeader extends Component {
    constructor(props) {
        super(props)
        this.search = this.search.bind(this);
    }

    search(event) {
        let el = event.currentTarget;
        let sorter = el.dataset.sorter;
        let searchInput = document.getElementById('searchString');
        let searchValue = searchInput.value;

        if (searchValue.indexOf(sorter) >= 0) {
            let split = searchValue.split(';');
            let existingIndex = split.findIndex(m => m.startsWith(sorter));
            if (existingIndex >= 0) {
                split.splice(existingIndex, 1);
                split.push(sorter + '=');
                searchInput.value = split.join(';');
            }
        } else {
            let split = searchValue.split(';');
            if (split[0].length == 0)
                split.splice(0, 1);

            split.push(sorter + '=');
            searchInput.value = split.join(';');
        }
        searchInput.focus();
    }

    render() {
        let searchButton = this.props.searchable ? <button className="btn nav-link" onClick={this.search} data-sorter={this.props.sorter || this.props.name.replace(/ /, '')}>
            <FontAwesomeIcon icon="search" />
        </button> : '';

        return (
            <th>
                <button className={this.props.className} onClick={this.props.onClick}>{this.props.name}</button>
                {searchButton}
            </th>
        )
    }
}
