import React, { Component } from 'react';
import { Http } from 'helpers/Http';
import { Util, SelectSearch } from 'helpers/Util';
import { AdminTable, TableHeader } from 'helpers/AdminTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export class ManageUser extends Component {
    static displayName = ManageUser.name;

    constructor(props) {
        super(props);
        let states = Util.injectState(this, {
            loading: false,
            title: "User",
            loadUrl: "/api/user",
            roles: []
        });

        let sortParams = {
            sorter: 'Email',
            isAscending: true,
            itemsPerPage: 50,
            searchString: '',
            currentPage: 1
        }
        this.state = AdminTable.injectState(this, states, sortParams);
        this.util = new Util(this);
        Http.init(this);
        this.http = Http;
        AdminTable.init(this);
        AdminTable.http = Http;

        this.loadRoles = this.loadRoles.bind(this);
        this.inputChanged = this.inputChanged.bind(this);
    }

    componentDidMount() {
        this.load(true, true);
        this.loadRoles();
        document.getElementById('searchString').value = this.state.searchString;
    }

    async loadRoles() {
        let res = await this.http.getData(null, '/api/roles');
        if (res != null) {
            await this.setState({ roles: res.data });
        }
    }

    inputChanged(event) {
        let name = event.currentTarget.name;
        let value = event.currentTarget.value;
        this.setState({
            editModel: {
                ID: this.state.editModel.ID,
                Email: name == 'Email' ? value : this.state.editModel.Email,
                Name: name == 'Name' ? value : this.state.editModel.Name,
                Password: name == 'Password' ? value : '',
                SelectedRoles: name == 'SelectedRoles' ? value : this.state.editModel.SelectedRoles,
            }
        })
    }

    renderTable() {
        return (
            <div className="card table-card">
                <div className="card-header">{this.state.title}</div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-4" style={{ paddingTop: '10px' }}>
                            <div className="input-group mb-3">
                                <input type="text" className="form-control" placeholder="Keywords" id="searchString" onKeyUp={this.searchKeyup} />
                                <div className="input-group-append">
                                    <button className="btn btn-secondary" type="button" id="searchList" onClick={this.search}>Search</button>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8" style={{ paddingTop: '10px', textAlign: 'right' }}>
                        </div>
                        <div className="col-12">
                            {
                                !this.state.selectMode ? <button onClick={this.toggleSelectMode} className="btn btn-secondary">Select</button> :
                                    <div>
                                        <button onClick={this.toggleSelectMode} className="btn btn-primary selection-btn">Select</button>
                                        <button onClick={this.selectAll} className="btn btn-secondary selection-btn">Select All</button>
                                        <button onClick={this.bulkDelete} className="btn btn-danger selection-btn">Bulk Delete</button>
                                    </div>
                            }
                        </div>
                    </div>
                </div>
                <div className="table-responsive" onScroll={this.scroll}>
                    <table className="table">
                        <thead>
                            <tr>
                                <TableHeader name="Email" className="btn nav-link th" onClick={this.sort} searchable={true} />
                                <TableHeader name="Name" className="btn nav-link th" onClick={this.sort} searchable={true} />
                                <TableHeader name="Roles" className="btn nav-link th" onClick={this.sort} searchable={true} />
                                <TableHeader name="Confirmed" className="btn nav-link th" onClick={this.sort} searchable={true} />
                                <TableHeader name="Date Created" className="btn nav-link th" onClick={this.sort} searchable={true} />
                            </tr>
                        </thead>

                        <tbody className="table-content">
                            {
                                this.state.viewModel != null ?
                                    this.state.viewModel.map((data) =>
                                        <tr data-row="yes" key={data.ID} data-id={data.ID}
                                            onClick={this.trClicked} className={this.state.selected == data.ID ? 'id-selected' : ''}
                                            style={{ cursor: 'pointer' }}>
                                            <td>{data.Email}</td>
                                            <td>{data.Name}</td>
                                            <td>{data.Roles.map(({ RoleName }) => RoleName).join(', ')}</td>
                                            <td className={data.Confirmed ? 'text-success' : 'text-danger'}>{data.Confirmed.toString()}</td>
                                            <td>{Util.displayDateTime(data.DateCreated)}</td>
                                        </tr>
                                    ) : <tr></tr>
                            }
                        </tbody>
                    </table>
                </div>
                <div style={{ textAlign: 'center' }} id="txt_scrollInfo" className="bg-info">{this.state.loading ? 'Loading...' : this.state.scrollInfo}</div>
            </div>   
        )
    }

    renderOptionModal() {
        let modal = this.state.optionModalOpen ? 
            <div id="option-modal" className="option-modal-container animate__animated animate__fadeIn animate__faster">
                <div className="option-modal-overlay" onClick={this.closeOptionModal}>
                </div>
                <div className="option-modal-content">
                    <div className="option-modal-header">
                        <h3>Action</h3>
                        <div className="btn option-modal-close" onClick={this.closeOptionModal}>X</div>
                        <hr/>
                    </div>
                    <div className="option-modal-body" style={{textAlign: 'center'}}>
                        <div className="option-modal-option" onClick={this.edit}>
                            <FontAwesomeIcon icon="edit" /> Edit
                        </div>
                        <div className="option-modal-option" onClick={this.delete}>
                            <FontAwesomeIcon icon="trash" /> Delete
                        </div>
                    </div>
                    <div className="option-modal-footer">
                        <hr />
                        <div className="btn btn-secondary" onClick={this.closeOptionModal}>Close</div>
                    </div>
                </div>
            </div>
            : <div></div>

        return modal;
    }

    renderEditModal() {
        let modal = this.state.editModalOpen ?
            <div id="edit-modal" className="option-modal-container animate__animated animate__fadeIn animate__faster">
                <div className="option-modal-overlay">
                </div>
                <form className="option-modal-content" onSubmit={this.state.editModalTitle == 'Edit' ? this.modify : this.create}>
                    <div className="option-modal-header">
                        <h3>{this.state.editModalTitle}</h3>
                        <div className="btn option-modal-close" onClick={this.closeEditModal}>X</div>
                        <hr />
                    </div>
                    <div className="option-modal-body">
                        <div className="form-group">
                            <label>Email</label>
                            <input name="Email" type="text" className="form-control" value={this.state.editModel.Email || ""} onChange={this.inputChanged} />
                        </div>
                        <div className="form-group">
                            <label>Name</label>
                            <input name="Name" type="text" className="form-control" value={this.state.editModel.Name || ""} onChange={this.inputChanged} />
                        </div>
                        <div className="form-group">
                            <label>Password</label>
                            <input name="Password" type="password" className="form-control" onChange={this.inputChanged} />
                        </div>
                        <div className="form-group">
                            <label>Roles</label>
                            <SelectSearch
                                multiple={true}
                                name="SelectedRoles"
                                className="form-control"
                                value={this.state.editModel.SelectedRoles}
                                options={this.state.roles.map((role) => { return { value: role.ID, name: role.RoleName, hide: false } })}
                                onChange={this.inputChanged}
                                required={true}
                            />
                        </div>
                    </div>
                    <div className="option-modal-footer">
                        <hr />
                        <button type="button" className="btn btn-secondary" onClick={this.closeEditModal}>Cancel</button>
                        <button type="submit" className="btn btn-primary">Submit</button>
                    </div>
                </form>
            </div>
            : <div></div>

        return modal;
    }

    render() {
        return (
            <div className="admin-content">
                <div>{this.util.render(this.state.notification, this.state.modal)}</div>
                {this.renderTable()}
                {this.renderOptionModal()}
                {this.renderEditModal()}
            </div>
        );
    }
}
