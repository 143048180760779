import React, { Component } from 'react';
import { Http } from 'helpers/Http';
import { Util } from 'helpers/Util';

export class WaitingForConfirmation extends Component {
    static displayName = WaitingForConfirmation.name;

    static setParam(msg, success) {
        return '/waitingForConfirmation?msg=' + encodeURI(msg) + '&type=' + (success ? 'success' : 'error');
    }

    constructor(props) {
        super(props);
        this.state = Util.injectState(this, {
            loading: false,
        });
        this.util = new Util(this);
        this.resend = this.resend.bind(this);
        Http.init(this);
    }

    componentDidMount() {
        if (this.state.params.msg && this.state.params.type == 'success')
            this.util.successNotification(this.state.params.msg);
        else if (this.state.params.msg && this.state.params.type == 'error')
            this.util.errorNotification(this.state.params.msg);
    }

    async resend(event) {
        event.preventDefault();
        this.setState({ loading: true });
        let result = await Http.postData(null, null, '/account/resendConfirmationEmail');
        this.setState({ loading: false });
        if (result != null) {
            if (result.success)
                this.util.successNotification(result.msg);
            else
                this.util.errorNotification(result.msg);
        }
    }

    render() {
        let button = this.state.loading ? '' : 'Resend Confirmation Email';

        return (
            <div>
                <div>{this.util.render(this.state.notification, this.state.modal)}</div>
                <div className="middle-vertical">
                    <div>
                        <h3>Your Email Needs To Be Confirmed</h3>
                        <p>
                            We have sent the email confirmation. Please kindly check your email to confirm your email.
                            If you have not received the confirmation email, click the button bellow to resend the confirmation email.
                            <br />
                            <br />
                        </p>
                        <div className="btn btn-primary" onClick={!this.state.loading ? this.resend : undefined}>
                            {this.util.renderLoading(this.state.loading, 'Loading...', true)}
                            {button}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
