import React, { Component } from 'react';
import { Http } from 'helpers/Http';
import { Util } from 'helpers/Util';
import { Link } from 'react-router-dom';

export class Login extends Component {
    static displayName = Login.name;

    static setParam(msg, success, redirect) {
        return '/login?msg=' + encodeURI(msg) + '&type=' + (success ? 'success' : 'error') + (redirect ? '&redirect=' + encodeURI(redirect) : '');
    }

    constructor(props) {
        super(props);
        this.state = Util.injectState(this,{
            loading: false,
            model: {
                email: null,
                password: null,
            } });
        this.util = new Util(this);
        this.login = this.login.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        Http.init(this);
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidMount() {
        if (this.state.params.msg && this.state.params.type == 'success')
            this.util.successNotification(this.state.params.msg);
        else if (this.state.params.msg && this.state.params.type == 'error')
            this.util.errorNotification(this.state.params.msg);
    }

    handleInputChange(e) {
        let el = e.currentTarget;
        this.setState({
            model: {
                email: el.id == 'email' ? el.value : this.state.model.email,
                password: el.id == 'password' ? el.value : this.state.model.password
            }
        })
    }

    async login(event) {
        event.preventDefault();
        this.setState({ loading: true });
        let result = await Http.postData(null, this.state.model, '/account/login');
        if (result != null) {
            if (result.success) {
                Util.global.setState({
                    isUserLoggedIn: true
                });
                this.util.successNotification('Success! You have been logged-in.');
                setTimeout(function () {
                    this.setState({ loading: false });
                    if (this.state.params.redirect)
                        Util.history.push(this.state.params.redirect);
                    else
                        Util.history.push(result.data);
                }.bind(this), 3000);
            } else {
                this.setState({ loading: false });
                this.util.errorNotification(result.msg);
            }
        }
    }

    render() {
        let button = this.state.loading ? '' :
            <button style={{ marginTop: "0.5em" }} type="submit" className="btn btn-primary">Login</button>

        return (
            <div className="form">
                <div>{this.util.render(this.state.notification, this.state.modal)}</div>
                <form onSubmit={this.login}>
                    <h1 style={{paddingBottom: "0.5em"}}>Login</h1>
                    <div className="form-group">
                        <input className="form-control" id="email" required type="text" placeholder="Email" onChange={this.handleInputChange} />
                    </div>

                    <div className="form-group">
                        <input className="form-control" id="password" required type="password" placeholder="Password" onChange={this.handleInputChange} />
                    </div>
                    {this.util.renderLoading(this.state.loading, 'Loading...', true)}
                    {button}

                    <div className="login-form-footer">
                        <span><Link to="/register">Register</Link></span>
                        <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
                        <span><Link to="/forgotPassword">Forgot Password</Link></span>
                    </div>
                </form>
            </div>
        );
    }
}
