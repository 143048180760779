import { Cookie } from 'helpers/Cookie';
import { Login } from 'components/account/Login';
import { WaitingForConfirmation } from 'components/account/WaitingForConfirmation';

export const Http = {
    parent: {},
    init: function (parent) {
        this.parent = parent;
    },
    deleteData: async function (params, url) {
        const response = await fetch(this.constructUrl(params, url), {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                // 'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': this.getAuthCookie(),
            },
        });
        return this.getResponse(response);
    },
    putData: async function (params, data, url) {
        const response = await fetch(this.constructUrl(params, url), {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                // 'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': this.getAuthCookie(),
            },
            body: JSON.stringify(data)
        });
        return await this.getResponse(response);
    },
    getData: async function (params, url) {
        const response = await fetch(this.constructUrl(params, url), {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                // 'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': this.getAuthCookie(),
            },
        });
        return this.getResponse(response);
    },
    postData: async function (params, data, url) {
        const response = await fetch(this.constructUrl(params, url), {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                // 'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': this.getAuthCookie(),
            },
            body: JSON.stringify(data)
        });
        return await this.getResponse(response);
    },
    getResponse: async function (response) {
        if (response.status == '200')
            return await response.json();
        else if (response.status == '401') //forbidden
        {
            this.parent.props.history.push(Login.setParam('Access denied! Please login to see the content', false, window.location.pathname));
            return null;
        }
        else if (response.status == '412') //need confirmation
        {
            this.parent.props.history.push(WaitingForConfirmation.setParam("Unconfirmed Email! Please confirm your email before accessing the content."));
            return null;
        }
        else
        {
            this.parent.util.errorNotification("Error: " + response.status);
            return null;
        }
    },
    getAuthCookie: function () {
        let authCookie = Cookie.getCookie("__TBATOKEN");
        if (authCookie.length > 0) {
            return 'Basic ' + authCookie;
        } else
            return '';
    },
    constructUrl: function (params, url) {
        return url + this.objToQueryString(params);
    },
    objToQueryString: function (obj) {
        const keyValuePairs = [];
        for (const key in obj) {
            if (Array.isArray(obj[key])) {
                obj[key].forEach((el) => {
                    keyValuePairs.push(encodeURIComponent(key) + '=' + encodeURIComponent(el));
                })
            } else {
                keyValuePairs.push(encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]));
            }
        }
        if (keyValuePairs.length > 0)
            return '?' + keyValuePairs.join('&');
        else
            return '';
    }
}