import 'components/Layout.scss';
import React, { Component } from 'react';
import PropTypes from "prop-types";
import { Container } from 'reactstrap';
import { NavMenu } from 'navigations/NavMenu';
import { Util } from 'helpers/Util'
import { Switch } from "react-router-dom";
import { withRouter } from 'react-router';
import { TransitionGroup, CSSTransition } from "react-transition-group";

class Layout extends Component {
    static displayName = Layout.name;
    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
    }

    constructor(props) {
        super(props);
        this.changeTheme = this.changeTheme.bind(this);
    }

    changeTheme(e) {
        let value = e.currentTarget.dataset.changeTo;
        if (value != Util.global.state.theme) {
            Util.global.setState({
                theme: value
            });
            localStorage.setItem('theme', value);
            let body = document.getElementsByTagName('body')[0];
            body.className = '';
            body.classList.add(value);
        }
    }

    render() {
        let themeBtn = Util.global.state.theme == null || Util.global.state.theme == 'light' ?
            <div className="btn btn-outline-secondary" data-change-to="dark" onClick={this.changeTheme}>Light</div> :
            <div className="btn btn-secondary" data-change-to="light" onClick={this.changeTheme}>Dark</div>

        const { location } = this.props;
        //const currentKey = location.pathname.split('/')[1] || '/'
        const timeout = { enter: 300, exit: 200 }

        return (
            <div>
                <NavMenu />
                <div className="general">
                    <Container fluid={true} className="relative padding-0">
                    <TransitionGroup className="transition-group">
                        <CSSTransition key={location.pathname} timeout={timeout} classNames="fade" appear>
                            <Switch location={location}>
                                {this.props.children}
                            </Switch>
                        </CSSTransition>
                    </TransitionGroup>
                </Container>
                </div>
            </div>
        );
    }
}

export default withRouter(Layout)