import 'custom.scss';
import React, { Component } from 'react';
import { Switch } from "react-router-dom";
import { Route, Router } from 'react-router';
import { Util } from 'helpers/Util';
import Layout from 'components/Layout';
import AdminLayout from 'components/AdminLayout';
import { Home } from 'components/home/Home';
import { FetchData } from 'components/home/FetchData';
import { Counter } from 'components/home/Counter';
import { Login } from 'components/account/Login';
import { Register } from 'components/account/Register';
import { WaitingForConfirmation } from 'components/account/WaitingForConfirmation';
import { ConfirmEmail } from 'components/account/ConfirmEmail';
import { ForgotPassword } from 'components/account/ForgotPassword';
import { ResetPassword } from 'components/account/ResetPassword';

//APPS
import { Calendar } from 'components/home/Calendar';

import { AdminDashboard } from 'components/admin/AdminDashboard';
import { ManageUser } from 'components/admin/ManageUser';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';

library.add(fas);
library.add(fab);

export default class App extends Component {
    static displayName = App.name;

    constructor(props) {
        super(props);
        Util.initGlobalState(this);
    }

    componentDidMount() {
        /*let body = document.getElementsByTagName('body')[0];
        //let theme = localStorage.getItem('theme');
        //if (theme == 'light') {
        //    this.setState({ theme: 'light' });
        //    body.classList.add('light');
        //}
        //else {
            this.setState({ theme: 'dark' });
            body.classList.add('dark');
        //}*/

    }

    render() {
        return (
            <Switch>
                <Route path='/admin/:path?' exact>
                    <AdminLayout>
                        <Switch>
                            <Route exact path='/admin' component={AdminDashboard} />
                            <Route path='/admin/manageUser' component={ManageUser} />
                        </Switch>
                    </AdminLayout>
                </Route>
                <Route>
                    <Layout>
                        <Route exact path='/' component={Home} />
                        <Route path='/counter' component={Counter} />
                        <Route path='/fetch-data' component={FetchData} />
                        <Route path='/login' component={Login} />
                        <Route path='/register' component={Register} />
                        <Route path='/waitingForConfirmation' component={WaitingForConfirmation} />
                        <Route path='/confirmEmail' component={ConfirmEmail} />
                        <Route path='/forgotPassword' component={ForgotPassword} />
                        <Route path='/resetPassword' component={ResetPassword} />

                        <Route path='/calendar' component={Calendar} />
                    </Layout>
                </Route>
            </Switch>
        );
    }
}
