import React, { Component } from 'react';
import { Http } from 'helpers/Http';
import { Util } from 'helpers/Util';

export class AdminDashboard extends Component {
    static displayName = AdminDashboard.name;

    constructor(props) {
        super(props);
        this.state = Util.injectState(this, {
            loading: false,
        });
        this.util = new Util(this);
        Http.init(this);
    }

    render() {

        return (
            <div className="admin-content">
                <div>{this.util.render(this.state.notification, this.state.modal)}</div>
                <div className="card">
                    <div className="card-header">Admin Dashboard</div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-4" style={{ paddingTop: '10px' }}>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
