import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Util } from 'helpers/Util';
import { Login } from 'components/account/Login';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export class AdminSideBar extends Component {
    static displayName = AdminSideBar.name;

    constructor(props) {
        super(props);
        this.util = new Util(this);
    }

    render() {
        let theme = localStorage.getItem('theme');
        let sidebarClass = "sidebar navbar-nav";
        let topbarMenuClass = "dropdown-menu dropdown-menu-right";

        return (
            <ul id="admin_sidebar" className={this.props.isOpen ? sidebarClass + ' show' : sidebarClass + ' hide'}>
                <li className="nav-item active">
                    <Link className="nav-link" to="/admin">
                        <FontAwesomeIcon className="nav-link-icon" icon="tachometer-alt" />
                        <span>Dashboard</span>
                    </Link>
                </li>
                <DropDownMenuToggle icon="box-open" text="Products">
                    <Link className="dropdown-item" to="/admin/manageProduct">
                        <FontAwesomeIcon className="nav-link-icon" icon='boxes' />
                        <span>Products</span>
                    </Link>
                </DropDownMenuToggle>
                <li className="nav-item">
                    <Link className="nav-link" to="/admin/manageUser">
                        <FontAwesomeIcon className="nav-link-icon" icon='user' />
                        <span>Users</span>
                    </Link>
                </li>
            </ul>
        );
    }
}

class DropDownMenuToggle extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false
        }
        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.setState({
            open: !this.state.open
        })
    }

    render() {
        return (
            <li className="nav-item dropdown">
                <button className="btn nav-link dropdown-toggle sidebar-dropdown" onClick={this.toggle}>
                    <FontAwesomeIcon className="nav-link-icon" icon={ this.props.icon } />
                    <span>{this.props.text}</span>
                    <FontAwesomeIcon className="caret" icon={ this.state.open ? 'caret-down' : 'caret-right' } />
                </button>
                <div className={this.state.open ? "dropdown-menu show" : "dropdown-menu" }>
                    { this.props.children }
                </div>
            </li>
        )
    }
}