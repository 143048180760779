import React, { Component } from 'react';
import { Http } from 'helpers/Http';
import { Util } from 'helpers/Util';

export class Register extends Component {
    static displayName = Register.name;

    constructor(props) {
        super(props);
        this.state = Util.injectState(this,{
            loading: false,
            model: {
                email: null,
                password: null,
                confirmPassword: null,
            } });
        this.util = new Util(this);
        this.register = this.register.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        Http.init(this);
    }

    handleInputChange(e) {
        let el = e.currentTarget;
        this.setState({
            model: {
                email: el.id == 'email' ? el.value : this.state.model.email,
                password: el.id == 'password' ? el.value : this.state.model.password,
                confirmPassword: el.id == 'confirmPassword' ? el.value : this.state.model.confirmPassword
            }
        })
    }

    async register(event) {
        event.preventDefault();
        if (this.state.model.password == this.state.model.confirmPassword) {
            this.setState({ loading: true });
            let result = await Http.postData(null, this.state.model, '/account/register');
            if (result != null) {
                this.setState({ loading: false });
                this.util.successNotification('Success! You have been registered.');
                setTimeout(function () {
                    this.props.history.push(result.data);
                }.bind(this), 3000);
            }
        } else {
            this.util.errorNotification("Password does not match");
        }
    }

    render() {
        let button = this.state.loading ? '' :
            <button style={{ marginTop: "0.5em" }} type="submit" className="btn btn-primary">Register</button>

        return (
            <div className="form">
                <div>{this.util.render(this.state.notification, this.state.modal)}</div>
                <form onSubmit={this.register}>
                    <h1 style={{paddingBottom: "0.5em"}}>Register</h1>
                    <div className="form-group">
                        <input className="form-control" id="email" required type="text" placeholder="Email" onChange={this.handleInputChange} />
                    </div>

                    <div className="form-group">
                        <input className="form-control" id="password" required type="password" placeholder="Password" onChange={this.handleInputChange} />
                    </div>

                    <div className="form-group">
                        <input className="form-control" id="confirmPassword" required type="password" placeholder="Confirm Password" onChange={this.handleInputChange} />
                    </div>

                    {this.util.renderLoading(this.state.loading)}
                    {button}
                </form>
            </div>
        );
    }
}
