import 'photoswipe/dist/photoswipe.css';
import 'photoswipe/dist/default-skin/default-skin.css';
import { Gallery, Item } from 'react-photoswipe-gallery';
import React, { Component } from 'react';

export class SimpleGallery extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const smallItemStyles = {
            cursor: 'pointer',
            objectFit: 'cover',
            width: '100%',
            maxHeight: '100%',
            maxWidth: '100%'
        }

        const ref = React.createRef();

        return (
            <Gallery id="simple-gallery">
                <div
                    style={{
                        display: 'grid',
                        gridTemplateColumns: '33.333% 33.333% 33.333%',
                        gridTemplateRows: 'auto auto auto',
                        gridGap: 12,
                    }}
                >
                    <Item
                        original="/assets/images/gallery/Blowing.jpg"
                        thumbnail="/assets/images/gallery/Blowing.jpg"
                        width="1600"
                        height="1600"
                        title="Blowing"
                    >
                        {({ ref, open }) => (
                            <img
                                style={smallItemStyles}
                                src="/assets/images/gallery/Blowing.jpg"
                                ref={ref}
                                onClick={open}
                            />
                        )}
                    </Item>
                    <Item
                        original="/assets/images/gallery/Carding.jpg"
                        thumbnail="/assets/images/gallery/Carding.jpg"
                        width="1600"
                        height="1600"
                        title="Carding"
                    >
                        {({ ref, open }) => (
                            <img
                                style={smallItemStyles}
                                src="/assets/images/gallery/Carding.jpg"
                                ref={ref}
                                onClick={open}
                            />
                        )}
                    </Item>
                    <Item
                        original="/assets/images/gallery/Drawing.jpg"
                        thumbnail="/assets/images/gallery/Drawing.jpg"
                        width="1600"
                        height="1600"
                        title="Drawing"
                    >
                        {({ ref, open }) => (
                            <img
                                style={smallItemStyles}
                                src="/assets/images/gallery/Drawing.jpg"
                                ref={ref}
                                onClick={open}
                            />
                        )}
                    </Item>
                    <Item
                        original="/assets/images/gallery/FlyFrame.jpg"
                        thumbnail="/assets/images/gallery/FlyFrame.jpg"
                        width="1600"
                        height="1600"
                        title="Fly Frame"
                    >
                        {({ ref, open }) => (
                            <img
                                style={smallItemStyles}
                                src="/assets/images/gallery/FlyFrame.jpg"
                                ref={ref}
                                onClick={open}
                            />
                        )}
                    </Item>
                    <Item
                        original="/assets/images/gallery/Research.jpg"
                        thumbnail="/assets/images/gallery/Research.jpg"
                        width="1600"
                        height="1600"
                        title="Research"
                    >
                        {({ ref, open }) => (
                            <img
                                style={smallItemStyles}
                                src="/assets/images/gallery/Research.jpg"
                                ref={ref}
                                onClick={open}
                            />
                        )}
                    </Item>
                    <Item
                        original="/assets/images/gallery/RingFrame.jpg"
                        thumbnail="/assets/images/gallery/RingFrame.jpg"
                        width="1600"
                        height="1600"
                        title="Ring Frame"
                    >
                        {({ ref, open }) => (
                            <img
                                style={smallItemStyles}
                                src="/assets/images/gallery/RingFrame.jpg"
                                ref={ref}
                                onClick={open}
                            />
                        )}
                    </Item>
                    <Item
                        original="/assets/images/gallery/Winding.jpg"
                        thumbnail="/assets/images/gallery/Winding.jpg"
                        width="1600"
                        height="1600"
                        title="Winding"
                    >
                        {({ ref, open }) => (
                            <img
                                style={smallItemStyles}
                                src="/assets/images/gallery/Winding.jpg"
                                ref={ref}
                                onClick={open}
                            />
                        )}
                    </Item>
                    <Item
                        original="/assets/images/gallery/UVInspection.jpg"
                        thumbnail="/assets/images/gallery/UVInspection.jpg"
                        width="1600"
                        height="1600"
                        title="UV Inspection"
                    >
                        {({ ref, open }) => (
                            <img
                                style={smallItemStyles}
                                src="/assets/images/gallery/UVInspection.jpg"
                                ref={ref}
                                onClick={open}
                            />
                        )}
                    </Item>
                    <Item
                        original="/assets/images/gallery/Warehousing.jpg"
                        thumbnail="/assets/images/gallery/Warehousing.jpg"
                        width="1600"
                        height="1600"
                        title="Werehousing"
                    >
                        {({ ref, open }) => (
                            <img
                                style={smallItemStyles}
                                src="/assets/images/gallery/Warehousing.jpg"
                                ref={ref}
                                onClick={open}
                            />
                        )}
                    </Item>
                </div>
            </Gallery>
        )
    }
}