export const DB = {
    db: null,
    init: function () {;
        this.db = openDatabase("calendarDB", "1.0", "Calendar DB", 10 * 1024 * 1024);
        this.createTable();
    },
    createTable: function () {
        let that = this;
        this.db.transaction(function (tx) {
            //tx.executeSql(`DROP TABLE settings;`);
            tx.executeSql(`CREATE TABLE IF NOT EXISTS events (id INTEGER PRIMARY KEY AUTOINCREMENT, title TEXT NOT NULL, location TEXT, allDay INTEGER, start INTEGER, end INTEGER, description TEXT, reminder TEXT, repeat INTEGER);`)
            tx.executeSql(`CREATE TABLE IF NOT EXISTS holidays (id INTEGER PRIMARY KEY AUTOINCREMENT, calendar TEXT NOT NULL, json TEXT, lastUpdated INTEGER);`)
            tx.executeSql(`CREATE TABLE IF NOT EXISTS settings (id INTEGER PRIMARY KEY AUTOINCREMENT, setting TEXT NOT NULL UNIQUE, value TEXT);`)
            that.seedDB(tx);
        }, (err) => console.log(err), () => console.log("Table creation is successful."));
    },
    seedDB: function (tx) {
        let settings = [
            { setting: 'selected country calendars', value: null },
            { setting: 'selected religion calendars', value: null },
        ];
        settings.forEach(function (el) {
            tx.executeSql(`INSERT OR IGNORE INTO settings (setting, value) VALUES (?,?)`, [el.setting, el.value]);
        });
    },
    updateQuery: function (query, values, cb) {
        this.db.transaction(function (tx) {
            tx.executeSql(query, values, function (tx, res) {
                return cb(res, null);
            });
        }, function (error) {
            return cb(null, error.message);
        }, function () {
            //console.log('updating ok');
        });
    },
    query: function (query, cb) {
        this.db.transaction(function (tx) {
            tx.executeSql(query, [], function (tx, res) {
                return cb(res.rows, null);
            });
        }, function (error) {
            return cb(null, error.message);
        }, function () {
            //console.log('query ok');
        });
    },
}