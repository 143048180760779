export const Religion =
[
    {
        "name": "Christian",
        "value": "christian"
    },
    {
        "name": "Jewish",
        "value": "judaism"
    },
    {
        "name": "Muslim",
        "value": "islamic"
    },
    {
        "name": "Orthodox",
        "value": "orthodox_christianity"
    }
]