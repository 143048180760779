import React, { Component } from 'react';
import {
    Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem,
    NavLink, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { Util } from 'helpers/Util';
import { Login } from 'components/account/Login';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AnchorLink from 'react-anchor-link-smooth-scroll';

export class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.closeNavbar = this.closeNavbar.bind(this);
        this.logout = this.logout.bind(this);
        this.state = {
            collapsed: true,
        };
        this.util = new Util(this);
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    async closeNavbar() {
        if (!this.state.collapsed)
            await this.setState({ collapsed: true });
    }

    logout() {
        this.util.showModal("Logout", "Are you sure?", async () => {
            let success = await Util.logout();
            await this.closeNavbar();
            if (success) {
                await Util.global.setState({
                    isUserLoggedIn: false
                });
                this.util.closeModal();
                Util.history.push(Login.setParam('Success! You have been logged-out.', true));
            }
        }, () => { this.util.closeModal(); });
    }

    render() {
        let theme = localStorage.getItem('theme');
        let navbarClass = "navbar navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3";
        let login = !Util.global.state.isUserLoggedIn ?
            <NavItem><NavLink className="text-light" onClick={this.closeNavbar} tag={Link} className={theme == 'dark' ? 'text-light' : 'text-dark'} to="/login">Login</NavLink></NavItem> :
            <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav>
                    <FontAwesomeIcon className="text-light" icon="user-circle" />
                </DropdownToggle>
                <DropdownMenu right>
                    <DropdownItem className="text-light">
                        Account
                    </DropdownItem>
                    <DropdownItem className="text-light" divider />
                    <DropdownItem className="text-light" onClick={this.logout}>
                        Logout
                    </DropdownItem>
                </DropdownMenu>
            </UncontrolledDropdown>


        return (
            <header className="dark">
                <div>{this.util.render(this.state.notification, this.state.modal)}</div>
                <nav className={navbarClass + ' navbar-dark background-dark margin-bottom-0 fixed-nav'}>
                    <div className="navbar-overlay dark"></div>
                    <Container className="text-light">
                        <AnchorLink onClick={this.closeNavbar} className="navbar-brand" href='#top'><img src="/assets/images/logo-transparent.png" style={{ height: '2rem' }} /><span style={{marginTop: 'auto', marginBottom: 'auto'}}> Tri Berkat Anugerah</span></AnchorLink>
                        <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
                        <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
                            <ul className="navbar-nav flex-grow">
                                <NavItem>
                                    <AnchorLink onClick={this.closeNavbar} className="text-light nav-link" href='#who-are-we'>Who Are We</AnchorLink>
                                </NavItem>
                                <NavItem>
                                    <AnchorLink onClick={this.closeNavbar} className="text-light nav-link" href='#products'>Products</AnchorLink>
                                </NavItem>
                                <NavItem>
                                    <AnchorLink onClick={this.closeNavbar} className="text-light nav-link" href='#gallery'>Gallery</AnchorLink>
                                </NavItem>
                                <NavItem>
                                    <AnchorLink onClick={this.closeNavbar} className="text-light nav-link" href='#location'>Location</AnchorLink>
                                </NavItem>
                            </ul>
                        </Collapse>
                    </Container>
                </nav>
            </header>
        );
    }
}
