export const Countries =
[
    {
        "name": "Afghanistan",
        "value": "af"
    },
    {
        "name": "Albania",
        "value": "al"
    },
    {
        "name": "Algeria",
        "value": "dz"
    },
    {
        "name": "American Samoa",
        "value": "as"
    },
    {
        "name": "Andorra",
        "value": "ad"
    },
    {
        "name": "Angola",
        "value": "ao"
    },
    {
        "name": "Anguilla",
        "value": "ai"
    },
    {
        "name": "Antarctica",
        "value": "aq"
    },
    {
        "name": "Antigua and Barbuda",
        "value": "ag"
    },
    {
        "name": "Argentina",
        "value": "ar"
    },
    {
        "name": "Armenia",
        "value": "am"
    },
    {
        "name": "Aruba",
        "value": "aw"
    },
    {
        "name": "Australia",
        "value": "australian"
    },
    {
        "name": "Austria",
        "value": "austrian"
    },
    {
        "name": "Azerbaijan",
        "value": "az"
    },
    {
        "name": "Bahamas",
        "value": "bs"
    },
    {
        "name": "Bahrain",
        "value": "bh"
    },
    {
        "name": "Bangladesh",
        "value": "bd"
    },
    {
        "name": "Barbados",
        "value": "bb"
    },
    {
        "name": "Belarus",
        "value": "by"
    },
    {
        "name": "Belgium",
        "value": "be"
    },
    {
        "name": "Belize",
        "value": "bz"
    },
    {
        "name": "Benin",
        "value": "bj"
    },
    {
        "name": "Bermuda",
        "value": "bm"
    },
    {
        "name": "Bhutan",
        "value": "bt"
    },
    {
        "name": "Bolivia (Plurinational State of)",
        "value": "bo"
    },
    {
        "name": "Bonaire, Sint Eustatius and Saba",
        "value": "bq"
    },
    {
        "name": "Bosnia and Herzegovina",
        "value": "ba"
    },
    {
        "name": "Botswana",
        "value": "bw"
    },
    {
        "name": "Bouvet Island",
        "value": "bv"
    },
    {
        "name": "Brazil",
        "value": "brazilian"
    },
    {
        "name": "British Indian Ocean Territory",
        "value": "io"
    },
    {
        "name": "Brunei Darussalam",
        "value": "bn"
    },
    {
        "name": "Bulgaria",
        "value": "bulgarian"
    },
    {
        "name": "Burkina Faso",
        "value": "bf"
    },
    {
        "name": "Burundi",
        "value": "bi"
    },
    {
        "name": "Cabo Verde",
        "value": "cv"
    },
    {
        "name": "Cambodia",
        "value": "kh"
    },
    {
        "name": "Cameroon",
        "value": "cm"
    },
    {
        "name": "Canada",
        "value": "canadian"
    },
    {
        "name": "Cayman Islands",
        "value": "ky"
    },
    {
        "name": "Central African Republic",
        "value": "cf"
    },
    {
        "name": "Chad",
        "value": "td"
    },
    {
        "name": "Chile",
        "value": "cl"
    },
    {
        "name": "China",
        "value": "china"
    },
    {
        "name": "Christmas Island",
        "value": "cx"
    },
    {
        "name": "Cocos (Keeling) Islands",
        "value": "cc"
    },
    {
        "name": "Colombia",
        "value": "co"
    },
    {
        "name": "Comoros",
        "value": "km"
    },
    {
        "name": "Congo",
        "value": "cg"
    },
    {
        "name": "Congo (Democratic Republic of the)",
        "value": "cd"
    },
    {
        "name": "Cook Islands",
        "value": "ck"
    },
    {
        "name": "Costa Rica",
        "value": "cr"
    },
    {
        "name": "Croatia",
        "value": "croatian"
    },
    {
        "name": "Cuba",
        "value": "cu"
    },
    {
        "name": "Curaçao",
        "value": "cw"
    },
    {
        "name": "Cyprus",
        "value": "cy"
    },
    {
        "name": "Czech Republic",
        "value": "czech"
    },
    {
        "name": "Côte d'Ivoire",
        "value": "ci"
    },
    {
        "name": "Denmark",
        "value": "danish"
    },
    {
        "name": "Djibouti",
        "value": "dj"
    },
    {
        "name": "Dominica",
        "value": "dm"
    },
    {
        "name": "Dominican Republic",
        "value": "do"
    },
    {
        "name": "Ecuador",
        "value": "ec"
    },
    {
        "name": "Egypt",
        "value": "eg"
    },
    {
        "name": "El Salvador",
        "value": "sv"
    },
    {
        "name": "Equatorial Guinea",
        "value": "gq"
    },
    {
        "name": "Eritrea",
        "value": "er"
    },
    {
        "name": "Estonia",
        "value": "ee"
    },
    {
        "name": "Ethiopia",
        "value": "et"
    },
    {
        "name": "Falkland Islands (Malvinas)",
        "value": "fk"
    },
    {
        "name": "Faroe Islands",
        "value": "fo"
    },
    {
        "name": "Fiji",
        "value": "fj"
    },
    {
        "name": "Finland",
        "value": "finnish"
    },
    {
        "name": "France",
        "value": "french"
    },
    {
        "name": "French Guiana",
        "value": "gf"
    },
    {
        "name": "French Polynesia",
        "value": "pf"
    },
    {
        "name": "French Southern Territories",
        "value": "tf"
    },
    {
        "name": "Gabon",
        "value": "ga"
    },
    {
        "name": "Gambia",
        "value": "gm"
    },
    {
        "name": "Georgia",
        "value": "ge"
    },
    {
        "name": "Germany",
        "value": "german"
    },
    {
        "name": "Ghana",
        "value": "gh"
    },
    {
        "name": "Gibraltar",
        "value": "gi"
    },
    {
        "name": "Greece",
        "value": "greek"
    },
    {
        "name": "Greenland",
        "value": "gl"
    },
    {
        "name": "Grenada",
        "value": "gd"
    },
    {
        "name": "Guadeloupe",
        "value": "gp"
    },
    {
        "name": "Guam",
        "value": "gu"
    },
    {
        "name": "Guatemala",
        "value": "gt"
    },
    {
        "name": "Guernsey",
        "value": "gg"
    },
    {
        "name": "Guinea",
        "value": "gn"
    },
    {
        "name": "Guinea-Bissau",
        "value": "gw"
    },
    {
        "name": "Guyana",
        "value": "gy"
    },
    {
        "name": "Haiti",
        "value": "ht"
    },
    {
        "name": "Heard Island and McDonald Islands",
        "value": "hm"
    },
    {
        "name": "Holy See",
        "value": "va"
    },
    {
        "name": "Honduras",
        "value": "hn"
    },
    {
        "name": "Hong Kong",
        "value": "hong_kong"
    },
    {
        "name": "Hungary",
        "value": "hungarian"
    },
    {
        "name": "Iceland",
        "value": "is"
    },
    {
        "name": "India",
        "value": "indian"
    },
    {
        "name": "Indonesia",
        "value": "indonesian"
    },
    {
        "name": "Iran (Islamic Republic of)",
        "value": "ir"
    },
    {
        "name": "Iraq",
        "value": "iq"
    },
    {
        "name": "Ireland",
        "value": "irish"
    },
    {
        "name": "Isle of Man",
        "value": "im"
    },
    {
        "name": "Israel",
        "value": "jewish"
    },
    {
        "name": "Italy",
        "value": "italian"
    },
    {
        "name": "Jamaica",
        "value": "jm"
    },
    {
        "name": "Japan",
        "value": "japanese"
    },
    {
        "name": "Jersey",
        "value": "je"
    },
    {
        "name": "Jordan",
        "value": "jo"
    },
    {
        "name": "Kazakhstan",
        "value": "kz"
    },
    {
        "name": "Kenya",
        "value": "ke"
    },
    {
        "name": "Kiribati",
        "value": "ki"
    },
    {
        "name": "Korea (Democratic People's Republic of)",
        "value": "kp"
    },
    {
        "name": "Korea (Republic of)",
        "value": "south_korea"
    },
    {
        "name": "Kuwait",
        "value": "kw"
    },
    {
        "name": "Kyrgyzstan",
        "value": "kg"
    },
    {
        "name": "Lao People's Democratic Republic",
        "value": "la"
    },
    {
        "name": "Latvia",
        "value": "latvian"
    },
    {
        "name": "Lebanon",
        "value": "lb"
    },
    {
        "name": "Lesotho",
        "value": "ls"
    },
    {
        "name": "Liberia",
        "value": "lr"
    },
    {
        "name": "Libya",
        "value": "ly"
    },
    {
        "name": "Liechtenstein",
        "value": "li"
    },
    {
        "name": "Lithuania",
        "value": "lithuanian"
    },
    {
        "name": "Luxembourg",
        "value": "lu"
    },
    {
        "name": "Macao",
        "value": "mo"
    },
    {
        "name": "Macedonia (the former Yugoslav Republic of)",
        "value": "mk"
    },
    {
        "name": "Madagascar",
        "value": "mg"
    },
    {
        "name": "Malawi",
        "value": "mw"
    },
    {
        "name": "Malaysia",
        "value": "malaysia"
    },
    {
        "name": "Maldives",
        "value": "mv"
    },
    {
        "name": "Mali",
        "value": "ml"
    },
    {
        "name": "Malta",
        "value": "mt"
    },
    {
        "name": "Marshall Islands",
        "value": "mh"
    },
    {
        "name": "Martinique",
        "value": "mq"
    },
    {
        "name": "Mauritania",
        "value": "mr"
    },
    {
        "name": "Mauritius",
        "value": "mu"
    },
    {
        "name": "Mayotte",
        "value": "yt"
    },
    {
        "name": "Mexico",
        "value": "mexican"
    },
    {
        "name": "Micronesia (Federated States of)",
        "value": "fm"
    },
    {
        "name": "Moldova (Republic of)",
        "value": "md"
    },
    {
        "name": "Monaco",
        "value": "mc"
    },
    {
        "name": "Mongolia",
        "value": "mn"
    },
    {
        "name": "Montenegro",
        "value": "me"
    },
    {
        "name": "Montserrat",
        "value": "ms"
    },
    {
        "name": "Morocco",
        "value": "ma"
    },
    {
        "name": "Mozambique",
        "value": "mz"
    },
    {
        "name": "Myanmar",
        "value": "mm"
    },
    {
        "name": "Namibia",
        "value": "na"
    },
    {
        "name": "Nauru",
        "value": "nr"
    },
    {
        "name": "Nepal",
        "value": "np"
    },
    {
        "name": "Netherlands",
        "value": "dutch"
    },
    {
        "name": "New Caledonia",
        "value": "nc"
    },
    {
        "name": "New Zealand",
        "value": "new_zealand"
    },
    {
        "name": "Nicaragua",
        "value": "ni"
    },
    {
        "name": "Niger",
        "value": "ne"
    },
    {
        "name": "Nigeria",
        "value": "ng"
    },
    {
        "name": "Niue",
        "value": "nu"
    },
    {
        "name": "Norfolk Island",
        "value": "nf"
    },
    {
        "name": "Northern Mariana Islands",
        "value": "mp"
    },
    {
        "name": "Norway",
        "value": "norwegian"
    },
    {
        "name": "Oman",
        "value": "om"
    },
    {
        "name": "Pakistan",
        "value": "pk"
    },
    {
        "name": "Palau",
        "value": "pw"
    },
    {
        "name": "Palestine, State of",
        "value": "ps"
    },
    {
        "name": "Panama",
        "value": "pa"
    },
    {
        "name": "Papua New Guinea",
        "value": "pg"
    },
    {
        "name": "Paraguay",
        "value": "py"
    },
    {
        "name": "Peru",
        "value": "pe"
    },
    {
        "name": "Philippines",
        "value": "philippines"
    },
    {
        "name": "Pitcairn",
        "value": "pn"
    },
    {
        "name": "Poland",
        "value": "polish"
    },
    {
        "name": "Portugal",
        "value": "portuguese"
    },
    {
        "name": "Puerto Rico",
        "value": "pr"
    },
    {
        "name": "Qatar",
        "value": "qa"
    },
    {
        "name": "Republic of Kosovo",
        "value": "xk"
    },
    {
        "name": "Romania",
        "value": "romanian"
    },
    {
        "name": "Russian Federation",
        "value": "russian"
    },
    {
        "name": "Rwanda",
        "value": "rw"
    },
    {
        "name": "Réunion",
        "value": "re"
    },
    {
        "name": "Saint Barthélemy",
        "value": "bl"
    },
    {
        "name": "Saint Helena, Ascension and Tristan da Cunha",
        "value": "sh"
    },
    {
        "name": "Saint Kitts and Nevis",
        "value": "kn"
    },
    {
        "name": "Saint Lucia",
        "value": "lc"
    },
    {
        "name": "Saint Martin (French part)",
        "value": "mf"
    },
    {
        "name": "Saint Pierre and Miquelon",
        "value": "pm"
    },
    {
        "name": "Saint Vincent and the Grenadines",
        "value": "vc"
    },
    {
        "name": "Samoa",
        "value": "ws"
    },
    {
        "name": "San Marino",
        "value": "sm"
    },
    {
        "name": "Sao Tome and Principe",
        "value": "st"
    },
    {
        "name": "Saudi Arabia",
        "value": "sa"
    },
    {
        "name": "Senegal",
        "value": "sn"
    },
    {
        "name": "Serbia",
        "value": "rs"
    },
    {
        "name": "Seychelles",
        "value": "sc"
    },
    {
        "name": "Sierra Leone",
        "value": "sl"
    },
    {
        "name": "Singapore",
        "value": "singapore"
    },
    {
        "name": "Sint Maarten (Dutch part)",
        "value": "sx"
    },
    {
        "name": "Slovakia",
        "value": "slovak"
    },
    {
        "name": "Slovenia",
        "value": "slovenian"
    },
    {
        "name": "Solomon Islands",
        "value": "sb"
    },
    {
        "name": "Somalia",
        "value": "so"
    },
    {
        "name": "South Africa",
        "value": "sa"
    },
    {
        "name": "South Georgia and the South Sandwich Islands",
        "value": "gs"
    },
    {
        "name": "South Sudan",
        "value": "ss"
    },
    {
        "name": "Spain",
        "value": "spain"
    },
    {
        "name": "Sri Lanka",
        "value": "lk"
    },
    {
        "name": "Sudan",
        "value": "sd"
    },
    {
        "name": "Suriname",
        "value": "sr"
    },
    {
        "name": "Svalbard and Jan Mayen",
        "value": "sj"
    },
    {
        "name": "Swaziland",
        "value": "sz"
    },
    {
        "name": "Sweden",
        "value": "swedish"
    },
    {
        "name": "Switzerland",
        "value": "ch"
    },
    {
        "name": "Syrian Arab Republic",
        "value": "sy"
    },
    {
        "name": "Taiwan",
        "value": "taiwan"
    },
    {
        "name": "Tajikistan",
        "value": "tj"
    },
    {
        "name": "Tanzania, United Republic of",
        "value": "tz"
    },
    {
        "name": "Thailand",
        "value": "th"
    },
    {
        "name": "Timor-Leste",
        "value": "tl"
    },
    {
        "name": "Togo",
        "value": "tg"
    },
    {
        "name": "Tokelau",
        "value": "tk"
    },
    {
        "name": "Tonga",
        "value": "to"
    },
    {
        "name": "Trinidad and Tobago",
        "value": "tt"
    },
    {
        "name": "Tunisia",
        "value": "tn"
    },
    {
        "name": "Turkey",
        "value": "turkish"
    },
    {
        "name": "Turkmenistan",
        "value": "tm"
    },
    {
        "name": "Turks and Caicos Islands",
        "value": "tc"
    },
    {
        "name": "Tuvalu",
        "value": "tv"
    },
    {
        "name": "Uganda",
        "value": "ug"
    },
    {
        "name": "Ukraine",
        "value": "ukrainian"
    },
    {
        "name": "United Arab Emirates",
        "value": "ae"
    },
    {
        "name": "United Kingdom of Great Britain and Northern Ireland",
        "value": "uk"
    },
    {
        "name": "United States Minor Outlying Islands",
        "value": "um"
    },
    {
        "name": "United States of America",
        "value": "usa"
    },
    {
        "name": "Uruguay",
        "value": "uy"
    },
    {
        "name": "Uzbekistan",
        "value": "uz"
    },
    {
        "name": "Vanuatu",
        "value": "vu"
    },
    {
        "name": "Venezuela (Bolivarian Republic of)",
        "value": "ve"
    },
    {
        "name": "Viet Nam",
        "value": "vietnamese"
    },
    {
        "name": "Virgin Islands (British)",
        "value": "vg"
    },
    {
        "name": "Virgin Islands (U.S.)",
        "value": "vi"
    },
    {
        "name": "Wallis and Futuna",
        "value": "wf"
    },
    {
        "name": "Western Sahara",
        "value": "eh"
    },
    {
        "name": "Yemen",
        "value": "ye"
    },
    {
        "name": "Zambia",
        "value": "zm"
    },
    {
        "name": "Zimbabwe",
        "value": "zw"
    },
    {
        "name": "Åland Islands",
        "value": "ax"
    }
]