import 'helpers/Util.scss';
import React, { Component } from 'react';
import * as moment from 'moment';
import queryString from 'query-string';
import { Cookie } from 'helpers/Cookie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export class Util extends Component {
    static displayName = Util.name;

    constructor(parent) {
        super(parent);
        this.parent = parent;
    }

    //Global
    static global = null;

    static initGlobalState(component) {
        component.state = {
            isUserLoggedIn: Util.isUserLoggedIn(),
            theme: null
        }
        Util.global = component;
    }

    //Init

    static history = null;

    static injectState(parent, states) {
        let state = {
            notification: null,
            loading: true,
            modal: null,
            params: queryString.parse(parent.props.location.search)
        }

        Object.keys(states).forEach(function (key) {
            state[key] = states[key];
        });

        if (parent.props.history)
            this.history = parent.props.history;

        return state;
    }

    //Loading

    renderLoading(showLoading, message, hideSpinner) {
        let msg = typeof message != 'undefined' ? message : 'Loading...';
        let content = !showLoading ? null : hideSpinner ? <div>{msg}</div> :
            <div className="loading">
                <div className="loading-content">
                    <div className="loadingio-spinner-eclipse">
                        <div className="ldio">
                            <div></div>
                        </div>
                    </div>
                    <div>{msg}</div>
                </div>
            </div>

        return (content);
    }

    //Notification

    successNotification(msg) {
        this.parent.setState({
            notification: {
                type: 'success',
                msg: msg
            }
        })
        setTimeout(() => this.removeNotification(), 3000);
    }

    errorNotification(msg) {
        this.parent.setState({
            notification: {
                type: 'danger',
                msg: msg
            }
        })
        //setTimeout(() => this.removeNotification(), 3000);
    }

    infoNotification(msg) {
        this.parent.setState({
            notification: {
                type: 'info',
                msg: msg
            }
        })
        setTimeout(() => this.removeNotification(), 3000);
    }

    removeNotification() {
        let element = document.getElementsByClassName('notification')[0];
        Util.animate(element, 'animate__fadeOut', function () {
            this.parent.setState({
                notification: null
            });
        }.bind(this), 200);
    }

    renderNotification(notification) {
        let content = notification == null ? null :
            <div className={'animate__animated animate__fadeIn notification alert alert-' + notification.type} role="alert">
                <div>{notification.msg}</div>
                <div className="btn close-btn" onClick={this.removeNotification.bind(this)}>X</div>
            </div>

        return (content);
    }

    //Modal

    closeModal() {
        let element = document.getElementsByClassName('confirm-modal')[0];
        Util.animate(element, 'animate__fadeOut', async function () {
            this.parent.setState({
                modal: null
            });
        }.bind(this), 200);
    }

    showModal(title, msg, yesAction, noAction, yesText, noText) {
        this.parent.setState({
            modal: {
                title: title,
                message: msg,
                yesButton: {
                    text: yesText ? yesText : 'Yes',
                    action: yesAction
                },
                noButton: {
                    text: noText ? noText : 'No',
                    action: noAction
                },
            }
        });
    }

    renderModal(modal) {
        let yesButton = modal && modal.yesButton ? modal.yesButton.action : null;
        this.yesButton = yesButton != null ? yesButton.bind(this.parent) : null;

        let noButton = modal && modal.yesButton ? modal.noButton.action : null;
        this.noButton = noButton != null ? noButton.bind(this.parent) : null;

        let content = modal == null ? null : 
            <div className="confirm-modal animate__animated animate__fadeIn">
                <div className="confirm-modal-overlay"></div>
                <div className="confirm-modal-content">
                    <div id="modal-close-btn" className="btn confirm-modal-close" onClick={this.closeModal.bind(this)}>X</div>
                    <div className="confirm-modal-title">{modal.title}</div>
                    <hr/>
                    <div className="confirm-modal-body">{modal.message}</div>
                    <div className="confirm-modal-footer">
                        {noButton == null ? null : <div className="btn btn-secondary btn-no" onClick={noButton}>{modal.noButton.text}</div>}
                        {yesButton == null ? null : <div className="btn btn-primary btn-yes" onClick={yesButton}>{modal.yesButton.text}</div>}
                    </div>
                </div>
            </div>

        return (content);
    }

    //Render

    render(notification, modal) {
        return (
            <div className="modalNotificationContainer">
                <div>{this.renderNotification(notification)}</div>
                <div>{this.renderModal(modal)}</div>
            </div>
        );
    }

    //Display Helpers
    static decimalSeparator = ',';
    static thousandSeparator = '.';

    static displayDate(date) {
        return moment(date).format('DD/MM/YYYY');
    }

    static displayDateTime(date) {
        return moment(date).format('DD/MM/YYYY HH:MM:SS');
    }

    static displayDateTime12Hour(date) {
        return moment(date).format('DD/MM/YYYY h:MM:SS A');
    }

    static formatThousands(amount, decimalCount = 2, decimal = Util.decimalSeparator, thousands = Util.thousandSeparator) {
        try {
            decimalCount = Math.abs(decimalCount);
            decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

            const negativeSign = amount < 0 ? "-" : "";

            let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
            let j = (i.length > 3) ? i.length % 3 : 0;

            return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
        } catch (e) {
            console.log(e)
        }
    };

    static displayNumber(amount) {
        return Util.formatThousands(amount, 0);
    }

    static displayDecimal2(amount) {
        return Util.formatThousands(amount, 2);
    }

    //Helpers

    static animate(element, animationClass, timeoutFunction, duration) {
        if (element) {
            element.classList.add(animationClass);
            setTimeout(timeoutFunction, duration);
        }
    }

    static isUserLoggedIn() {
        return Cookie.getCookie("__TBATOKEN") != "";
    }

    static async logout() {
        var authCookie = Cookie.getCookie("__TBATOKEN");
        if (authCookie.length > 0)
            authCookie = 'Basic ' + authCookie;
        else
            authCookie = '';

        let response = await fetch('/account/logout', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                // 'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': authCookie,
            },
        });

        let result = await response.json();
        return result.success;
    }

    static uuid () {
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );
    }

    static constructUrlParams(params) {
        let p = [];
        Object.keys(params).forEach(function (key) {
            p.push(key + '=' + params[key]);
        });
        return encodeURI(p.join('&'));
    }
}

export class SelectSearch extends Component {
    constructor(props) {
        super(props)

        let value = this.props.multiple ? [...this.props.value] || [] : this.props.value;
        let options = [...this.props.options];
        options.forEach((el) => {
            if (this.props.multiple) {
                el.selected = value.findIndex(m => m == el.value) >= 0 ? true : false;
            } else {
                el.selected = el.value == value ? true : false;
            }
        })
        if (!this.props.multiple && !this.props.required) {
            options.splice(0, 0, {
                selected: false,
                value: '',
                name: '-- None --',
                hide: false,
            })
        }

        let displayValue = this.props.multiple ? options.filter((m) => this.props.value.findIndex(x => x == m.value) >= 0).map((m) => m.name).join(', ') :
            options.filter((m) => m.value == this.props.value).map((m) => m.name)[0]

        this.state = {
            id: Util.uuid(),
            value: value,
            displayValue: displayValue == '' ? this.props.placeholder ? this.props.placeholder : '-- Select --' : displayValue,
            searchMode: false,
            options: options
        }

        this.changeValue = this.changeValue.bind(this);
        this.toggle = this.toggle.bind(this);
        this.search = this.search.bind(this);
        this.selectOption = this.selectOption.bind(this);
        this.getDisplayValue = this.getDisplayValue.bind(this);
        this.triggerChange = this.triggerChange.bind(this);
    }

    changeValue(event) {
        this.setState({
            value: event.currentTarget.value,
        })
    }

    async toggle(event) {
        let input = event.currentTarget.nextSibling.querySelector('.select-search-input-search');
        await this.setState({ searchMode: !this.state.searchMode });
        if (this.state.searchMode) {
            let container = document.getElementById(this.state.id);
            container.style.maxHeight = (document.documentElement.scrollHeight - container.getBoundingClientRect().top - 15) + 'px';
            input.focus();
        } else {
            let input = document.querySelector('input[data-input-for="' + this.state.id + '"]');
            input.value = '';
            this.search({ currentTarget: input });
        }
    }

    search(event) {
        let searchValue = event.currentTarget.value.toLowerCase();
        let options = [...this.state.options];
        options.forEach((el) => {
            if (el.name.toLowerCase().includes(searchValue))
                el.hide = false;
            else
                el.hide = true;
        })
        this.setState({
            options: options
        })
    }

    getDisplayValue() {
        let displayValue = this.props.multiple ? this.state.options.filter((m) => this.state.value.findIndex(x => x == m.value) >= 0).map((m) => m.name).join(', ') :
            this.state.options.filter((m) => m.value == this.state.value).map((m) => m.name)[0]
        this.setState({
            displayValue: displayValue
        })
    }

    async selectOption(event) {
        let value = event.currentTarget.dataset.value;
        let options = [...this.state.options];

        if (this.props.multiple) {
            let selectedValue = [...this.state.value];
            let existingIndex = selectedValue.findIndex(m => m == value);
            if (existingIndex >= 0) {
                if (!this.props.required || selectedValue.length != 1) {
                    selectedValue.splice(existingIndex, 1);
                    let optIndex = options.findIndex(m => m.value == value);
                    options[optIndex].selected = false;
                }
            }
            else {
                let optIndex = options.findIndex(m => m.value == value);
                options[optIndex].selected = true;
                selectedValue.push(value);
            }
            await this.setState({ value: selectedValue, options: options });
        }
        else {
            options.forEach((el) => {
                el.selected = el.value == value ? true : false;
            })
            await this.setState({ value: value, options: options })
        }
        await this.getDisplayValue();
        this.triggerChange();
    }

    triggerChange() {
        this.props.onChange({ currentTarget: { name: this.props.name, value: this.state.value } });
        if (!this.props.multiple) {
            let input = document.querySelector('input[data-input-for="' + this.state.id + '"]');
            input.value = '';
            this.search({ currentTarget: input });
            this.setState({ searchMode: false });
        }
    }

    render() {
        return (
            <div>
                <div className={this.props.className + ' select-search-display' + (this.state.searchMode ? ' hide' : '')} onClick={this.toggle}>
                    <div>{this.state.displayValue}</div>
                    <div>
                        <FontAwesomeIcon icon="caret-down" />
                    </div>
                </div>
                <div className={this.props.className + ' select-search-search' + (this.state.searchMode ? '' : ' hide')}>
                    <div className="select-search-search-overlay" onClick={this.toggle}></div>
                    <input data-input-for={this.state.id} className="select-search-input-search" onChange={this.search} placeholder="Search" />
                    <div onClick={this.toggle}>
                        <FontAwesomeIcon icon="caret-up" />
                    </div>
                    <div className="select-search-search-options">
                        <div>
                            <div className="select-search-search-option-container" id={this.state.id}>
                                {
                                    this.state.options.map((opt) =>
                                        <div
                                            key={opt.value}
                                            className={"select-search-option" + (opt.hide ? ' hide' : '') + (opt.selected ? ' selected' : '')}
                                            data-value={opt.value}
                                            onClick={this.selectOption}
                                        >
                                            <span>{opt.name}</span>
                                            {opt.selected ? <FontAwesomeIcon icon="check" /> : ''}
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}