import React, { Component } from 'react';
import { Http } from 'helpers/Http';
import { Util } from 'helpers/Util';

export class ResetPassword extends Component {
    static displayName = ResetPassword.name;

    constructor(props) {
        super(props);
        this.state = Util.injectState(this,{
            loading: false,
            model: {
                password: null,
                confirmPassword: null,
            } });
        this.util = new Util(this);
        this.reset = this.reset.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        Http.init(this);
    }

    handleInputChange(e) {
        let el = e.currentTarget;
        this.setState({
            model: {
                email: el.id == 'email' ? el.value : this.state.model.email,
                password: el.id == 'password' ? el.value : this.state.model.password,
                confirmPassword: el.id == 'confirmPassword' ? el.value : this.state.model.confirmPassword
            }
        })
    }

    async reset(event) {
        event.preventDefault();
        if (this.state.model.password == this.state.model.confirmPassword) {
            this.setState({ loading: true });
            let result = await Http.postData(null, { hash: this.state.params.hash, password: this.state.model.password }, '/account/resetPassword');
            if (result != null) {
                this.setState({ loading: false });

                if (result.success) {
                    this.util.successNotification(result.msg);
                    setTimeout(function () {
                        this.props.history.push(result.data);
                    }.bind(this), 3000);
                }
                else
                    this.util.errorNotification(result.msg);
            }
        } else {
            this.util.errorNotification("Password does not match");
        }
    }

    render() {
        let button = this.state.loading ? '' :
            <button style={{ marginTop: "0.5em" }} type="submit" className="btn btn-primary">Reset</button>

        return (
            <div className="form">
                <div>{this.util.render(this.state.notification, this.state.modal)}</div>
                <form onSubmit={this.reset}>
                    <h1 style={{ paddingBottom: "0.5em" }}>Reset Password</h1>

                    <div className="form-group">
                        <input className="form-control" id="password" required type="password" placeholder="New Password" onChange={this.handleInputChange} />
                    </div>

                    <div className="form-group">
                        <input className="form-control" id="confirmPassword" required type="password" placeholder="Confirm Password" onChange={this.handleInputChange} />
                    </div>

                    {this.util.renderLoading(this.state.loading)}
                    {button}
                </form>
            </div>
        );
    }
}
