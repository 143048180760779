import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Util } from 'helpers/Util';
import { Login } from 'components/account/Login';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons'

library.add(fas);

export class AdminTopBar extends Component {
    static displayName = AdminTopBar.name;

    constructor(props) {
        super(props);

        this.toggleTopBarMenu = this.toggleTopBarMenu.bind(this);
        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.closeNavbar = this.closeNavbar.bind(this);
        this.logout = this.logout.bind(this);
        this.state = {
            collapsed: true,
            topbarMenuOpen: false,
        };
        this.util = new Util(this);
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    toggleTopBarMenu() {
        this.setState({
            topbarMenuOpen: !this.state.topbarMenuOpen
        });
    }

    closeNavbar() {
        if (!this.state.collapsed)
            this.setState({ collapsed: true });
    }

    logout() {
        this.util.showModal("Logout", "Are you sure?", async () => {
            let success = await Util.logout();
            this.closeNavbar();
            if (success) {
                await Util.global.setState({
                    isUserLoggedIn: false
                });
                this.util.closeModal();
                Util.history.push(Login.setParam('Success! You have been logged-out.', true));
            }
        }, () => { this.util.closeModal(); });
    }

    render() {
        let theme = localStorage.getItem('theme');
        let navbarClass = "navbar navbar-expand static-top";
        let topbarMenuClass = "dropdown-menu dropdown-menu-right";

        return (
            <div>
                <div>{this.util.render(this.state.notification, this.state.modal)}</div>
                <nav id="admin_navbar" className={theme == 'dark' ? navbarClass + ' navbar-dark' : navbarClass + ' navbar-light'}>
                    <button className="navbar-brand" onClick={this.props.sidebarToggle}>TBA</button>
                    <button className="btn btn-sm topbar-bars" onClick={this.props.sidebarToggle}>
                        <FontAwesomeIcon icon="bars" />
                    </button>
                    <ul className="navbar-nav ml-auto topbar-nav">
                        <li className="nav-item dropdown no-arrow">
                            <button className="btn nav-link dropdown-toggle" onClick={this.toggleTopBarMenu}>
                                <FontAwesomeIcon icon="user-circle" />
                            </button>
                            <div className={this.state.topbarMenuOpen ? topbarMenuClass + ' show' : topbarMenuClass}>
                                <Link className="dropdown-item" to="/userDashboard">Account</Link>
                                <div className="dropdown-divider"></div>
                                <Link onClick={this.logout} className="dropdown-item" to="#" id="btn_logout" data-toggle="modal" data-target="#logoutModal">Logout</Link>
                            </div>
                        </li>
                    </ul>
                </nav>
            </div>
        );
    }
}