import 'components/home/Calendar.scss';
import React, { Component, createRef } from 'react';
import { Util, SelectSearch } from 'helpers/Util';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as moment from 'moment';
import { Http } from 'helpers/Http';
import { Countries } from 'data/countries';
import { Religion } from 'data/religion';
import { DB } from 'helpers/DB';
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";

export class Calendar extends Component {
    static displayName = Calendar.name;

    constructor(props) {
        super(props);
        this.state = Util.injectState(this, {
            today: moment().startOf('day'),
            currentDate: moment().startOf('day'),
            openBrowseDate: false,
            scope: 'month',
            searchYear: moment().year(),
            publicHolidays: [],
            events: [],
            selectedCountryCalendars: [],
            selectedReligionCalendars: [],
            displayEventForm: false,
            displaySearchForm: false,
            form: null,
            searchedEvents: [],
            searchFormInitiated: false,
            popover: false,
            settingOpened: false,
        });

        this.util = new Util(this);
        Http.init(this);

        this.searchEventsToday = createRef();

        this.toggleOpenBrowseDate = this.toggleOpenBrowseDate.bind(this);
        this.setCurrentDate = this.setCurrentDate.bind(this);
        this.navigatePrevMonth = this.navigatePrevMonth.bind(this);
        this.navigateNextMonth = this.navigateNextMonth.bind(this);
        this.backToToday = this.backToToday.bind(this);
        this.setSearchScope = this.setSearchScope.bind(this);
        this.selectMonthOfTheYear = this.selectMonthOfTheYear.bind(this);
        this.navigatePrevYear = this.navigatePrevYear.bind(this);
        this.navigateNextYear = this.navigateNextYear.bind(this);

        this.getHoliday = this.getHoliday.bind(this);
        this.getHolidayData = this.getHolidayData.bind(this);

        this.closeEventForm = this.closeEventForm.bind(this);
        this.displayNewEventForm = this.displayNewEventForm.bind(this);
        this.formOnChange = this.formOnChange.bind(this);
        this.startDateChanged = this.startDateChanged.bind(this);
        this.endDateChanged = this.endDateChanged.bind(this);
        this.saveEvent = this.saveEvent.bind(this);
        this.getEvents = this.getEvents.bind(this);
        this.editEvent = this.editEvent.bind(this);
        this.deleteEvent = this.deleteEvent.bind(this);
        this.displayHolidayDetail = this.displayHolidayDetail.bind(this);
        this.searchEvents = this.searchEvents.bind(this);

        this.displaySearchForm = this.displaySearchForm.bind(this);
        this.searchChanged = this.searchChanged.bind(this);
        this.closeSearchForm = this.closeSearchForm.bind(this);
        this.searchScrollToToday = this.searchScrollToToday.bind(this);

        this.togglePopover = this.togglePopover.bind(this);
        this.toggleSetting = this.toggleSetting.bind(this);
        this.countryCalendarChanged = this.countryCalendarChanged.bind(this);
        this.religionCalendarChanged = this.religionCalendarChanged.bind(this);
        this.getSelectedCalendars = this.getSelectedCalendars.bind(this);

        this.refreshData = this.refreshData.bind(this);
        this.refreshSearch = this.refreshSearch.bind(this);

        this.lang = 'en';
        this.thisMonth = null;
        this.currDate = null;
        this.periodBefore = 12; //months
        this.periodAfter = 12; //months

        this.countryList = Countries;
        this.religionList = Religion;
    }

    componentDidMount() {
        DB.init();
        this.getSelectedCalendars();
        this.getEvents();
    }

    setSearchScope(event) {
        let scope = event.currentTarget.dataset.scope;
        this.setState({
            scope: scope
        })
    }

    //Month Scope

    navigatePrevMonth() {
        let currYear = this.state.currentDate.year();
        let month = this.state.currentDate.month();
        let date = new Date(currYear, month - 1, 1, 0, 0, 0, 0);
        this.refreshData(null, null, moment(date));
        this.setState({
            currentDate: moment(date),
            searchYear: moment(date).year()
        })
    }

    navigateNextMonth() {
        let currYear = this.state.currentDate.year();
        let month = this.state.currentDate.month();
        let date = new Date(currYear, month + 1, 1, 0, 0, 0, 0);
        this.refreshData(null, null, moment(date));
        this.setState({
            currentDate: moment(date),
            searchYear: moment(date).year()
        })
    }

    toggleOpenBrowseDate() {
        this.setState({
            openBrowseDate: !this.state.openBrowseDate
        });
    }

    setCurrentDate(event, searchDate) {
        let date = event ? event.currentTarget.dataset.date : searchDate;
        let currDate = moment(date).startOf('day');

        this.currDate = currDate;
        this.refreshData(null,null, currDate);

        this.setState({
            currentDate: currDate,
            searchYear: moment(date).year()
        })
    }

    backToToday(event) {
        this.refreshData(null,null,this.state.today);
        this.setState({
            currentDate: this.state.today
        })
        this.searchScrollToToday();
    }

    static getMonthData(currentDate, selectedDate, today, allHolidays, allEvents) {
        let thisMonthDates = [];
        let thisYear = selectedDate.format('YYYY');
        let thisMonth = selectedDate.format('M') - 1;
        let daysInMonth = selectedDate.daysInMonth();
        let startOfMonth = moment(selectedDate).subtract(1, 'month').startOf('month');
        let endOfMonth = moment(selectedDate).add(1,'month').endOf('month');

        let holidays = allHolidays.filter(m => m.start.isBetween(startOfMonth, endOfMonth, null, '[]'));
        let events = allEvents.filter(m => (m.repeat == 'never' || m.repeat == '') && m.start.isBetween(startOfMonth, endOfMonth, null, '[]'));
        let repeatingEvents = Calendar.getRepeatingEvents(startOfMonth, endOfMonth, allEvents);
        events = events.concat(repeatingEvents);
        events = events.sort((a, b) => a.start.diff(b.start));

        for (var i = 1; i <= daysInMonth; i++) {
            let date = moment(new Date(thisYear, thisMonth, i, 0, 0, 0, 0));
            let startOfDate = moment(date).startOf('day');
            let endOfDate = moment(date).endOf('day');
            thisMonthDates.push({
                date: date,
                events: events.filter(m => moment(m.start).isBetween(startOfDate, endOfDate, null, '[]') || moment(m.end).isBetween(startOfDate, endOfDate, null, '[]')),
                holidays: holidays.filter(m => date.isBetween(m.start, m.end, null, '[]')),
                isCurrentMonth: true,
                isToday: date.format('DD/MM/YYYY') == today.format('DD/MM/YYYY'),
                isCurrentDate: date.format('DD/MM/YYYY') == currentDate.format('DD/MM/YYYY'),
            });
        }

        //Append previous month
        let firstDay = thisMonthDates[0].date.days();
        if (firstDay < 7) { //is not sunday
            for (var i = 0; i < firstDay; i++) {
                let firstDay = new Date(thisYear, thisMonth, 1, 0, 0, 0, 0);
                let date = moment(firstDay.setDate(firstDay.getDate() - (i + 1)));
                let startOfDate = moment(date).startOf('day');
                let endOfDate = moment(date).endOf('day');
                thisMonthDates.unshift({
                    date: date,
                    events: events.filter(m => moment(m.start).isBetween(startOfDate, endOfDate, null, '[]') || moment(m.end).isBetween(startOfDate, endOfDate, null, '[]')),
                    holidays: holidays.filter(m => date.isBetween(m.start, m.end, null, '[]')),
                    isCurrentMonth: false,
                    isToday: date.format('DD/MM/YYYY') == today.format('DD/MM/YYYY'),
                    isCurrentDate: date.format('DD/MM/YYYY') == currentDate.format('DD/MM/YYYY')
                });
            }
        }

        //Append next month
        let total = thisMonthDates.length <= 35 ? 35 : 42;

        if (thisMonthDates.length < total) {
            let nextMonthDays = [];
            for (var i = thisMonthDates.length; i < total; i++) {
                let lastDay = thisMonthDates[thisMonthDates.length - 1].date.toDate();
                let date = moment(lastDay.setDate(lastDay.getDate() + (total - i)));
                let startOfDate = moment(date).startOf('day');
                let endOfDate = moment(date).endOf('day');
                nextMonthDays.unshift({
                    date: date,
                    events: events.filter(m => moment(m.start).isBetween(startOfDate, endOfDate, null, '[]') || moment(m.end).isBetween(startOfDate, endOfDate, null, '[]')),
                    holidays: holidays.filter(m => date.isBetween(m.start, m.end, null, '[]')),
                    isCurrentMonth: false,
                    isToday: date.format('DD/MM/YYYY') == today.format('DD/MM/YYYY'),
                    isCurrentDate: date.format('DD/MM/YYYY') == currentDate.format('DD/MM/YYYY')
                });
            }
            thisMonthDates = thisMonthDates.concat(nextMonthDays);
        }

        return thisMonthDates;
    }

    static getRepeatingEvents(startOfDate, endOfDate, allEvents, oneDayOnly, noDuplicates, excludeDaily) {
        let events = [];

        let daily = allEvents.filter(m => m.repeat == 'day');
        let weekly = allEvents.filter(m => m.repeat == 'week');
        let monthly = allEvents.filter(m => m.repeat == 'month');
        let yearly = allEvents.filter(m => m.repeat == 'year');

        let totalDays = oneDayOnly ? 1 : endOfDate.diff(startOfDate, 'day');

        for (var i = 0; i < totalDays; i++) {
            let theDate = moment(startOfDate).add(i, 'day');
            if (!excludeDaily) {
                daily.forEach((el) => {
                    let ev = { ...el };
                    let duration = moment.duration(ev.end.diff(ev.start));
                    ev.start = Calendar.setTime(theDate, ev.start);
                    ev.end = moment(ev.start).add(duration);
                    events.push(ev);

                    if (!noDuplicates) {
                        let f1 = ev.start.format('YYYY/MM/DD');
                        let f2 = ev.end.format('YYYY/MM/DD');

                        if (f1 != f2) {
                            let ev2 = { ...el };
                            ev2.end = Calendar.setTime(theDate, ev2.end);
                            ev2.start = moment(ev2.end).subtract(duration);
                            events.push(ev2);
                        }
                    }
                });
            }
            weekly.forEach((el) => {
                if (theDate.day() == el.start.day() || (!noDuplicates && (theDate.day() == el.end.day()))) {
                    let ev = { ...el };
                    let durationDays = 0;
                    if (theDate.day() == el.start.day())
                        durationDays = theDate.diff(moment(ev.start).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }), 'day');
                    else
                        durationDays = theDate.diff(moment(ev.end).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }), 'day');

                    ev.start = moment(ev.start).add(durationDays, 'day');
                    ev.end = moment(ev.end).add(durationDays, 'day');
                    events.push(ev);
                }
            });
            monthly.forEach((el) => {
                if (theDate.date() == el.start.date() || (!noDuplicates && (theDate.date() == el.end.date()))) {
                    let ev = { ...el };
                    let durationMonths = 0;
                    if (theDate.day() == el.start.day())
                        durationMonths = theDate.diff(moment(ev.start).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }), 'month');
                    else
                        durationMonths = theDate.diff(moment(ev.end).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }), 'month');

                    ev.start = moment(ev.start).add(durationMonths, 'month');
                    ev.end = moment(ev.end).add(durationMonths, 'month');
                    events.push(ev);
                }
            });
            yearly.forEach((el) => {
                if ((theDate.date() == el.start.date() && theDate.month() == el.start.month()) || (!noDuplicates && ((theDate.date() == el.end.date() && theDate.month() == el.end.month())))) {
                    let ev = { ...el };
                    let durationYears = 0;
                    if (theDate.day() == el.start.day())
                        durationYears = theDate.diff(moment(ev.start).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }), 'year');
                    else
                        durationYears = theDate.diff(moment(ev.end).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }), 'year');

                    ev.start = moment(ev.start).add(durationYears, 'year');
                    ev.end = moment(ev.end).add(durationYears, 'year');
                    events.push(ev);
                }
            });
        }

        return events;
    }

    static setTime(theDate, fromDate) {
        let date = moment(theDate);
        return date.set({ hour: fromDate.get('hour'), minute: fromDate.get('minute'), second: fromDate.get('second') });
    }

    static constructMonthDate(currentDate, selectedDate, today, setCurrentDate, holidays, events) {
        let data = Calendar.getMonthData(currentDate, selectedDate, today, holidays, events);
        let rows = [];
        let totalRow = Math.ceil(data.length / 7);

        for (var row = 0; row < totalRow; row++) {
            let rowData = [];
            for (var col = 0; col < 7; col++) {
                let index = row * 7 + col;
                if (index < data.length) {
                    let date = data[index];
                    let classname = 'date-date ' + (!date.isCurrentMonth ? 'not-current-month ' : '') + (date.isToday ? 'today ' : '') + (date.holidays.length > 0 ? 'holiday' : '');
                    let eventDot = date.events.length > 0 ? <div className="dot-container"><span className="event-dot">.</span></div> : '';
                    rowData.push(
                        <td key={date.date.format('DD/MM/YYYY')} className="date">
                            {eventDot}
                            <div className={'table-div ' + (date.isCurrentDate ? 'current-date ' : '')} data-date={date.date.toISOString()} onClick={setCurrentDate}>
                                <span className={classname}>{date.date.date()}</span>
                            </div>
                        </td>
                    );
                }
            }
            rows.push(rowData);
        }

        return rows;
    }

    static constructMonthTable(data) {
        return (
            <tbody>
                {
                    data.map((row, index) =>
                        <tr key={index}>
                            {
                                row.map(date => date)
                            }
                        </tr>
                    )
                }
            </tbody>
        )
    }

    //

    //Year Scope

    selectMonthOfTheYear(event) {
        let month = event.currentTarget.dataset.month;
        let date = new Date(this.state.searchYear, month - 1, 1, 0, 0, 0, 0);

        this.refreshData(null, null, moment(date));

        this.setState({
            currentDate: moment(date),
            scope: 'month',
        })
    }

    navigateNextYear() {
        this.setState({
            searchYear: this.state.searchYear + 1
        })
    }

    navigatePrevYear() {
        this.setState({
            searchYear: this.state.searchYear - 1
        })
    }

    //

    //Http request

    async getHoliday() {
        if (this.state.selectedCountryCalendars.length > 0) {
            let countries = this.countryList.filter(m => this.state.selectedCountryCalendars.some(x => m.name == x));
            let religions = this.religionList.filter(m => this.state.selectedReligionCalendars.some(x => m.name == x));
            let publicHolidays = [];
            for await (var country of countries) {
                publicHolidays = publicHolidays.concat(await this.getHolidayData(country));
            }
            for await (var religion of religions) {
                publicHolidays = publicHolidays.concat(await this.getHolidayData(religion));
            }
            this.refreshData(null, publicHolidays);
            this.setState({
                publicHolidays: publicHolidays
            })
        }
    }

    async getHolidayData(country) {
        return new Promise((resolve, reject) => {
            DB.query(`SELECT * FROM holidays WHERE calendar = '` + country.name + `'`, async (rows, error) => {
                if (rows.length == 0 || moment().diff(moment(rows[0].lastUpdated), 'month') >= 1) {
                    const response = await fetch('https://clients6.google.com/calendar/v3/calendars/' + this.lang + '.' + country.value + '%23holiday@group.v.calendar.google.com/events?calendarId=' + this.lang + '.' + country.value + '%23holiday%40group.v.calendar.google.com&singleEvents=true&maxAttendees=1&maxResults=250&sanitizeHtml=true&key=AIzaSyCsZWJziTdgcZmfpFEYFzlaMEage9aoCq8', {
                        method: 'GET',
                    });
                    let holidays = await response.json();
                    if (holidays.error) {
                        alert("The holiday data is not available (" + country.name + ")");
                    } else {
                        let data = holidays.items.map(m => {
                            return { id: m.id, country: country.name, event: m.summary, start: moment(m.start.date, 'YYYY-MM-DD'), end: moment(m.end.date, 'YYYY-MM-DD').subtract(1, 'minute') };
                        });
                        let query = rows.length == 0 ? 'INSERT INTO holidays (calendar, json, lastUpdated) VALUES(?1,?2,?3)' :
                            'UPDATE holidays SET calendar = ?1, json = ?2, lastUpdated = ?3';

                        DB.updateQuery(query, [country.name, JSON.stringify(data), moment().valueOf()], (rows, error) => {
                            if (error != null)
                                alert(error);
                        });
                        resolve(data);
                    }
                } else {
                    let data = JSON.parse(rows[0].json);
                    data.forEach((el) => {
                        el.start = moment(el.start);
                        el.end = moment(el.end);
                    })
                    resolve(data);
                }
            })
        })        
    }

    /*async getCountries() {
        const response = await fetch('https://restcountries.eu/rest/v2/all', {
            method: 'GET',
        });
        let countries = await response.json();
        await this.setState({
            countryList: countries.map(m => {
                //let langISO = m.languages[0].iso639_1 != null ? m.languages[0].iso639_1.toLowerCase() : 'en';
                return { name: m.name, value: this.lang + '.' + m.alpha2Code.toLowerCase(), demonym: m.demonym};
            })
        })
        this.state.countryList.forEach(async (el) => {
            await this.getHoliday(el);
        })
    }

    async getHoliday(country) {
        const response = await fetch('https://www.googleapis.com/calendar/v3/calendars/' + country.value + '%23holiday%40group.v.calendar.google.com/events?key=AIzaSyCsZWJziTdgcZmfpFEYFzlaMEage9aoCq8', {
            method: 'GET',
        });
        let holidays = await response.json();
        if (holidays.error) {
            await this.getHolidayByCountryName(country);
        } else {
            this.existingGoogleCalendar.push({
                country: country.name,
                value: country.value
            })
        }
    }

    async getHolidayByCountryName(country) {
        let split = country.value.split('.');
        split[1] = country.name.replace(/ /g, '_').toLowerCase();

        const response = await fetch('https://www.googleapis.com/calendar/v3/calendars/' + split.join('.') + '%23holiday%40group.v.calendar.google.com/events?key=AIzaSyCsZWJziTdgcZmfpFEYFzlaMEage9aoCq8', {
            method: 'GET',
        });
        let holidays = await response.json();
        if (holidays.error) {
            await this.getHolidayByDemonym(country);
        } else {
            this.existingGoogleCalendar.push({
                country: country.name,
                value: split.join('.')
            })
        }
    }

    async getHolidayByDemonym(country) {
        let split = country.value.split('.');
        split[1] = country.demonym.toLowerCase();

        const response = await fetch('https://www.googleapis.com/calendar/v3/calendars/' + split.join('.') + '%23holiday%40group.v.calendar.google.com/events?key=AIzaSyCsZWJziTdgcZmfpFEYFzlaMEage9aoCq8', {
            method: 'GET',
        });
        let holidays = await response.json();
        if (holidays.error) {
            let special = this.specialCalendar.filter(m => m.country == country.name)[0];
            this.existingGoogleCalendar.push({
                country: country.name,
                value: special.code
            })
        } else {
            this.existingGoogleCalendar.push({
                country: country.name,
                value: split.join('.')
            })
        }
    }*/

    //Events

    closeEventForm() {
        this.setState({
            displayEventForm: false
        })
    }

    displayNewEventForm() {
        let form = {
            about: 'New Event',
            title: '',
            location: '',
            allDay: false,
            start: moment(this.state.currentDate),
            end: moment(this.state.currentDate).add(1, 'hour'),
            reminder: 'none',
            description: '',
            repeat: 'never'
        }
        this.setState({
            displayEventForm: true,
            form: form
        })
    }

    startDateChanged(date) {
        let form = { ...this.state.form };
        form.start = moment(date);

        this.setState({
            form: form
        })
    }

    endDateChanged(date) {
        let form = { ...this.state.form };
        form.end = moment(date);

        this.setState({
            form: form
        })
    }

    formOnChange(event) {
        let el = event.currentTarget.dataset.form;
        let value = event.currentTarget.value;
        let checked = event.currentTarget.checked;

        let isAllDay = el == 'allDay' && checked;
        let allDayStart = isAllDay ? moment(this.state.currentDate).startOf('day') : null;
        let allDayEnd = isAllDay ? moment(this.state.currentDate).endOf('day') : null;

        let form = {
            id: this.state.form.id,
            about: this.state.form.about,
            title: el == 'title' ? value : this.state.form.title,
            location: el == 'location' ? value : this.state.form.location,
            allDay: el == 'allDay' ? checked : this.state.form.allDay,
            start: el == 'start' ? value : isAllDay ? allDayStart : this.state.form.start,
            end: el == 'end' ? value : isAllDay ? allDayEnd : this.state.form.end,
            reminder: el == 'reminder' ? value : this.state.form.reminder,
            description: el == 'description' ? value : this.state.form.description,
            repeat: el == 'repeat' ? value : this.state.form.repeat,
        }

        this.setState({
            form: form
        })
    }

    getEvents(id, cb) {
        let whereClause = id ? `WHERE id = '` + id + `'` : ``; 
        DB.query('SELECT * FROM events ' + whereClause + ` ORDER BY start ASC`, (rows, error) => {
            if (error != null) {
                this.util.errorNotification(error);
            } else {
                var data = [];
                if (rows != null) {
                    for (var i = 0; i < rows.length; i++) {
                        let item = rows.item(i);
                        let returnObject = {
                            id: item.id,
                            title: item.title,
                            location: item.location,
                            allDay: item.allDay != 0 ? true : false,
                            start: moment(item.start),
                            end: moment(item.end),
                            reminder: item.reminder,
                            description: item.description,
                            repeat: item.repeat
                        };
                        data.push(returnObject);
                    }

                    if (cb) {
                        return cb(data)
                    } else {
                        this.refreshData(data, null);
                        this.setState({
                            events: data
                        })
                    }
                }
            }
        });
    }

    saveEvent(event) {
        event.preventDefault();

        if (moment(this.state.form.start).isValid() && moment(this.state.form.end).isValid()) {
            if (moment(this.state.form.end).isSameOrAfter(moment(this.state.form.start))) {
                var keys = [];
                var values = [];
                var indexes = [];
                var updates = [];
                var updateId = null;

                for (var key in this.state.form) {
                    if (key != 'about' && key != 'id') {
                        if (key == 'start' || key == 'end')
                            values.push(this.state.form[key].valueOf());
                        else if (key == 'allDay')
                            values.push(this.state.form[key] ? 1 : 0);
                        else
                            values.push(this.state.form[key]);

                        keys.push(key);
                        indexes.push('?' + keys.length);
                        updates.push(key + '=' + '?' + keys.length);
                    } else {
                        if (key == 'id') {
                            updateId = this.state.form.id;
                        }
                    }
                }

                if (this.state.form.about != 'Edit Event') {
                    DB.updateQuery(`INSERT INTO events (` + keys.join(',') + `) VALUES (` + indexes.join(',') + `)`, values,
                        (res, error) => {
                            if (error == null && res.rowsAffected > 0) {
                                this.util.successNotification('Success! The event has been saved');
                                let events = [...this.state.events];
                                this.getEvents(res.insertId, (data) => {
                                    events.push(data[0]);
                                    events = events.sort((a, b) => a.start.diff(b.start));
                                    this.refreshData(events, null);
                                    this.setState({
                                        displayEventForm: false,
                                        events: events,
                                        searchedEvents: this.state.displaySearchForm ? this.refreshSearch(events, null) : this.state.searchedEvents
                                    });
                                });
                            } else {
                                if (error != null) {
                                    this.util.errorNotification(error);
                                }
                            }
                        }
                    );
                } else {
                    DB.updateQuery(`UPDATE events SET ` + updates.join(',') + ` WHERE id = '` + updateId + `'`, values,
                        (res, error) => {
                            if (error == null && res.rowsAffected > 0) {
                                this.util.successNotification('Success! The event has been saved');
                                let events = [...this.state.events];
                                this.getEvents(updateId, (data) => {
                                    events.splice(events.findIndex(m => m.id == updateId), 1, data[0]);
                                    events = events.sort((a, b) => a.start.diff(b.start));
                                    this.refreshData(events, null);
                                    this.setState({
                                        displayEventForm: false,
                                        events: events,
                                        searchedEvents: this.state.displaySearchForm ? this.refreshSearch(events, null) : this.state.searchedEvents
                                    });
                                });
                            } else {
                                if (error != null) {
                                    this.util.errorNotification(error);
                                }
                            }
                        }
                    );
                }
            } else {
                alert('The end date must be later than the start date');
            }
        }
        else {
            alert('The start or the end date is invalid');
        }
        
    }

    editEvent(event) {
        var id = event.currentTarget.dataset.id;
        let start = event.currentTarget.dataset.start;
        let end = event.currentTarget.dataset.end;

        let form = { ...this.state.events.filter(m => m.id == id)[0] };
        form.about = 'Edit Event';
        form.start = moment(start);
        form.end = moment(end);
        this.setState({
            displayEventForm: true,
            form: form
        })

        if (this.state.displaySearchForm)
            this.setCurrentDate(null, start);
    }

    displayHolidayDetail(event) {
        var id = event.currentTarget.dataset.id;

        let holiday = { ...this.state.publicHolidays.filter(m => m.id == id)[0] };

        this.setState({
            displayEventForm: true,
            form: {
                about: 'Public Holiday',
                title: holiday.event,
                location: '',
                allDay: true,
                start: holiday.start,
                end: holiday.end,
                reminder: '',
                description: '',
                repeat: '',
            }
        })

        if (this.state.displaySearchForm)
            this.setCurrentDate(null, holiday.start);
    }

    deleteEvent() {
        var id = this.state.form.id;

        this.util.showModal('Delete Event', 'Are you sure?', () => {
            DB.updateQuery(`DELETE FROM events WHERE id = ?1`, [id],
                (res, error) => {
                    if (res.rowsAffected > 0) {
                        this.util.successNotification("Success! The entry has been deleted");
                        let events = [...this.state.events];
                        events.splice(events.findIndex(m => m.id == id), 1);
                        this.setState({
                            events: events,
                            displayEventForm: false,
                        })
                    }
                    if (error != null)
                        this.util.errorNotification("Error! The entry cannot be deleted. " + error);

                    this.util.closeModal();
                });
        }, () => {this.util.closeModal()})
    }

    //Search

    searchScrollToToday() {
        if (this.searchEventsToday && this.searchEventsToday.current)
            this.searchEventsToday.current.scrollIntoView();
    }

    async displaySearchForm() {
        this.refreshData();
        if (!this.state.searchFormInitiated) {
            await this.searchChanged(null, true);
            this.searchScrollToToday();
        } else {
            let searchedEvents = this.refreshSearch();
            await this.setState({
                displaySearchForm: true,
                searchedEvents: searchedEvents
            })
            this.searchScrollToToday();
        }
        setTimeout(function () {
            let searchInput = document.getElementById('input-search');
            searchInput.focus();
        }, 500);
    }

    refreshSearch(events, holidays) {
        let allEvents = events ? events : this.state.events;
        let allHolidays = holidays ? holidays : this.state.publicHolidays;
        let input = document.getElementById('input-search').value;
        return this.searchEvents(input.toLowerCase(), allEvents, allHolidays);
    }

    async searchChanged(event, isAwaited) {
        this.periodBefore = 12;
        this.periodAfter = 12;

        let value = event ? event.currentTarget.value.toLowerCase() : '';
        let combined = this.searchEvents(value, this.state.events, this.state.publicHolidays);

        if (isAwaited)
            this.setState({ displaySearchForm: true, searchFormInitiated: true, searchedEvents: combined })
        else
            await this.setState({ displaySearchForm: true, searchFormInitiated: true, searchedEvents: combined })
    }

    searchEvents(value, allEvents, allHolidays) {
        let events = allEvents.length > 0 ? [...allEvents.filter(m => (m.repeat == '' || m.repeat == 'never') && m.title.toLowerCase().includes(value))] : [];
        let repeatingEvents = Calendar.getRepeatingEvents(moment().subtract(this.periodBefore, 'month'), moment().add(this.periodAfter, 'month'), allEvents.filter(m => m.repeat != '' && m.repeat != 'never' && m.title.toLowerCase().includes(value)), false, true, true);
        events = events.concat(repeatingEvents);
        let holidays = allHolidays.length > 0 ? [...allHolidays.filter(m => m.event.toLowerCase().includes(value) || m.country.toLowerCase().includes(value))] : [];
        let combined = [];

        events.forEach((ev) => {
            let index = combined.findIndex(m => m.date == ev.start.format('dddd, MMM DD, YYYY'));
            if (index >= 0) {
                let existed = combined[index];
                existed.events.push(ev);
            } else {
                combined.push({
                    date: ev.start.format('dddd, MMM DD, YYYY'),
                    events: [ev],
                    holidays: [],
                    moment: moment(ev.start).startOf('day')
                })
            }

            if (ev.start.date() != ev.end.date()) {
                let index = combined.findIndex(m => m.date == ev.end.format('dddd, MMM DD, YYYY'));
                if (index >= 0) {
                    let existed = combined[index];
                    existed.events.push(ev);
                } else {
                    combined.push({
                        date: ev.end.format('dddd, MMM DD, YYYY'),
                        events: [ev],
                        holidays: [],
                        moment: moment(ev.end).startOf('day')
                    })
                }
            }

        });

        holidays.forEach((hol) => {
            let index = combined.findIndex(m => m.date == moment(hol.start).format('dddd, MMM DD, YYYY'));
            if (index >= 0) {
                let existed = combined[index];
                existed.holidays.push(hol);
            } else {
                combined.push({
                    date: moment(hol.start).format('dddd, MMM DD, YYYY'),
                    events: [],
                    holidays: [hol],
                    moment: moment(hol.start).startOf('day')
                })
            }
        });

        if (combined.findIndex(m => m.moment.diff(this.state.today, 'day') == 0) < 0) {
            combined.push({
                date: this.state.today.format('dddd, MMM DD, YYYY'),
                events: [],
                holidays: [],
                moment: moment(this.state.today)
            })
        }

        return combined.sort((a, b) => a.moment.diff(b.moment));
    }

    closeSearchForm() {
        this.setState({
            displaySearchForm: false
        })
    }

    //

    //Helpers

    togglePopover() {
        this.setState({
            popover: !this.state.popover
        })
    }

    toggleSetting() {
        this.setState({
            settingOpened: !this.state.settingOpened,
            popover: false
        })
    }

    countryCalendarChanged(event) {
        let value = event.currentTarget.value;
        DB.updateQuery(`UPDATE settings SET value = ?1 WHERE setting = 'selected country calendars'`, [value.join(',')], (rows, error) => {
            if (error != null)
                alert(error);
            else {
                this.setState({
                    selectedCountryCalendars: value
                })

                this.getHoliday();
            }
        })
    }

    religionCalendarChanged(event) {
        let value = event.currentTarget.value;
        DB.updateQuery(`UPDATE settings SET value = ?1 WHERE setting = 'selected religion calendars'`, [value.join(',')], (rows, error) => {
            if (error != null)
                alert(error);
            else {
                this.setState({
                    selectedReligionCalendars: value
                })

                this.getHoliday();
            }
        })
    }

    getSelectedCalendars() {
        DB.query(`SELECT value FROM settings WHERE setting = 'selected country calendars'`, (rows, error) => {
            DB.query(`SELECT value FROM settings WHERE setting = 'selected religion calendars'`, async (rowss, errors) => {
                let selected1 = rows[0].value;
                let selected2 = rowss[0].value;
                await this.setState({
                    selectedCountryCalendars: selected1 == null ? [] : selected1.split(','),
                    selectedReligionCalendars: selected2 == null ? [] : selected2.split(',')
                });
                this.getHoliday();
            })
        })
    }

    refreshData(events, holidays, currDate) {
        let publicHolidays = holidays ? holidays : this.state.publicHolidays;
        let theEvents = events ? events : this.state.events;
        let currentDate = currDate ? currDate : this.state.currentDate;

        this.thisMonth = Calendar.constructMonthTable(Calendar.constructMonthDate(currentDate, currentDate, this.state.today, this.setCurrentDate, publicHolidays, theEvents));
    }

    //

    //Render

    static renderSearchForm(that) {
        let html =
            <div className={"form-search-container " + (that.state.displaySearchForm ? '' : 'hide')}>
                <div onClick={that.closeSearchForm} style={{ textAlign: 'right' }}><FontAwesomeIcon icon="times" /></div>
                <div className="search-form">
                    <div className="border-bottom"><input id="input-search" onChange={that.searchChanged} className="form-control" placeholder="Search events" /></div>
                </div>
                <div className="event-content">
                    {
                        that.state.searchedEvents.map(event => {
                            let startOfDate = moment(event.moment).startOf('day');
                            let endOfDate = moment(event.moment).endOf('day');
                            let isPast = moment(that.state.today).endOf('day').diff(startOfDate, 'day') > 0;
                            let eventsOfTheDay = Calendar.renderEventsList(that, event.holidays, event.events, startOfDate, endOfDate, isPast);
                            let isToday = that.state.today.diff(event.moment, 'day') == 0;

                            return (
                                <div className="row" key={event.date} ref={isToday ? that.searchEventsToday : null}>
                                    <div className="col-12">
                                        <h3 className={(isToday ? 'is-today' : '') + (isPast ? 'is-past' : '')}>{event.date}</h3>
                                    </div>
                                    <div style={{ width: '100%', padding: '1rem' }}>
                                        {eventsOfTheDay}
                                    </div>
                                </div>
                            )
                        }
                        )
                    }
                </div>
                <div className="search-container-footer">
                    <div className="btn" onClick={that.backToToday}>
                        <div className="back-to-today"><span>{that.state.today.date()}</span></div>
                    </div>
                </div>
            </div>

        return html;
    }

    static renderEvents(that) {
        let publicHolidays = that.state.publicHolidays.filter(m => that.state.currentDate.isBetween(m.start, m.end, null, '[]'));
        let startOfDate = moment(that.state.currentDate).startOf('day');
        let endOfDate = moment(that.state.currentDate).endOf('day');
        let events = that.state.events.filter(m => (m.repeat == 'never' || m.repeat == '') && (m.start.isBetween(startOfDate, endOfDate, null, '[]') || m.end.isBetween(startOfDate, endOfDate, null, '[]')));
        let repeatingEvents = Calendar.getRepeatingEvents(startOfDate, endOfDate, that.state.events, true);
        events = events.concat(repeatingEvents);
        events = events.sort((a, b) => a.start.diff(b.start));

        let isPast = that.state.currentDate < that.state.today;

        let html = Calendar.renderEventsList(that, publicHolidays, events, startOfDate, endOfDate, isPast);

        return (html)
    }

    static renderEventsList(that, publicHolidays, events, startOfDate, endOfDate, isPast) {
        let publicHolidaysHtml = publicHolidays.length == 0 && events.length == 0 ? <div>There is no event</div> :
            publicHolidays.length > 0 ?
                publicHolidays.map(m =>
                    <div className="row event-row" key={m.id} data-id={m.id} onClick={that.displayHolidayDetail}>
                        <div className={'col-3 event-time' + (isPast ? ' is-past' : '')}><div className="event-desc">All day</div></div>
                        <div className={'col-9 event-title' + (isPast ? ' is-past' : '')}><div className="event-desc is-holiday">{m.event} ({m.country})</div></div>
                    </div>
                ) : '';

        let eventsHtml = [];

        events = events.sort((a, b) => {
            let first = a.start.date() == startOfDate.date() ? a.start : a.end;
            let second = b.start.date() == startOfDate.date() ? b.start : b.end;
            return first.diff(second);
        });

        if (events.length > 0) {
            events.forEach((m,i) => {
                let html = [];
                html.push(Calendar.constructEventsListHtml(that, m, startOfDate, endOfDate, isPast));
                eventsHtml = eventsHtml.concat(html);
            });
        }

        return (
            <div>
                {publicHolidaysHtml}
                {eventsHtml}
            </div>
        );
    }

    static constructEventsListHtml(that, m, startOfDate, endOfDate, isPast) {
        let html = m.allDay ?
            <div data-id={m.id} data-start={m.start.toDate()} data-end={m.end.toDate()} onClick={that.editEvent} className="row event-row" key={m.id + m.start.toDate()}>
                <div className={'col-3 event-time' + (isPast ? ' is-past' : '')}><div className="event-desc">All day</div></div>
                <div className={'col-9 event-title' + (isPast ? ' is-past' : '')}><div className="event-desc">{m.title}</div></div>
            </div>
            :
            m.start.isBetween(startOfDate, endOfDate, null, '[]') &&
                m.end.isBetween(startOfDate, endOfDate, null, '[]') ?
                <div data-id={m.id} data-start={m.start.toDate()} data-end={m.end.toDate()} onClick={that.editEvent} className="row event-row" key={m.id + m.start.toDate()}>
                    <div className={'col-3 event-time' + (isPast ? ' is-past' : '')}><div>{m.start.format('hh:mm A')}</div><div>{m.end.format('hh:mm A')}</div></div>
                    <div className={'col-9 event-title' + (isPast ? ' is-past' : '')}><div className="event-desc">{m.title}</div></div>
                </div>
                : m.start.isBetween(startOfDate, endOfDate, null, '[]') ?
                    <div data-id={m.id} data-start={m.start.toDate()} data-end={m.end.toDate()} onClick={that.editEvent} className="row event-row" key={m.id + m.start.toDate()}>
                        <div className={'col-3 event-time' + (isPast ? ' is-past' : '')}><div>{m.start.format('hh:mm A')}</div><div className="small-text">Start</div></div>
                        <div className={'col-9 event-title' + (isPast ? ' is-past' : '')}><div className="event-desc">{m.title}</div></div>
                    </div>
                    :
                    <div data-id={m.id} data-start={m.start.toDate()} data-end={m.end.toDate()} onClick={that.editEvent} className="row event-row" key={m.id + m.start.toDate()}>
                        <div className={'col-3 event-time' + (isPast ? ' is-past' : '')}><div>{m.end.format('hh:mm A')}</div><div className="small-text">End</div></div>
                        <div className={'col-9 event-title' + (isPast ? ' is-past' : '')}><div className="event-desc">{m.title}</div></div>
                    </div>
            ;

        return html;
    }

    static renderEventForm(that) {
        let html = that.state.displayEventForm ?
            <div className="form-event-container">
                <form className="event-form" onSubmit={that.saveEvent}>
                    <div className="row">
                        <div className="col-2 centering" onClick={that.closeEventForm}><FontAwesomeIcon className="pointer" icon="times" /></div>
                        <div className="col-7 centering-v"><h3>{that.state.form.about}</h3></div>
                        <div className="col-1 centering-v"><div onClick={that.deleteEvent} style={{ marginLeft: 'auto', cursor: 'pointer', display: that.state.form.id ? '' : 'none' }}><FontAwesomeIcon icon="trash" /></div></div>
                        <div className="col-1 centering-v"><button type="submit" style={{ marginLeft: 'auto', cursor: 'pointer' }}><FontAwesomeIcon icon="check" /></button></div>

                        <div className="col-12"><hr /></div>
                        <div className="col-2 centering"><FontAwesomeIcon icon="heading" /></div>
                        <div className="col-9 centering-v border-bottom"><input data-form="title" value={that.state.form.title || ''} onChange={that.formOnChange} required className="form-control" type="text" placeholder="Title" /></div>

                        <div className="col-12"><hr /></div>
                        <div className="col-2 centering"><FontAwesomeIcon icon="map-marker-alt" /></div>
                        <div className="col-9 centering-v border-bottom"><input data-form="location" value={that.state.form.location || ''} onChange={that.formOnChange} className="form-control" type="text" placeholder="Location" /></div>

                        <div className="col-12"><hr /></div>
                        <div className="col-2 centering"><FontAwesomeIcon icon="clock" /></div>
                        <div className="col-9 centering-v border-bottom"><div>All day</div>
                            <label className="switch margin-left-auto">
                                <input data-form="allDay" checked={that.state.form.allDay || false} onChange={that.formOnChange} type="checkbox" /><span className="slider round"></span>
                            </label>
                        </div>

                        <div className="col-12"><hr /></div>
                        <div className="col-2 centering"></div>
                        <div className="col-3 centering-v border-bottom"><div>Start</div></div>
                        <div className="col-6 centering-v border-bottom">
                            <div style={{ marginLeft: 'auto' }}>
                                <Datetime
                                    inputProps={{
                                        disabled: that.state.form.allDay,
                                        required: true,
                                        className: 'form-control no-border text-align-right'
                                    }}
                                    dateFormat="DD-MMM-YYYY"
                                    timeFormat="hh:mm A"
                                    value={that.state.form.start || moment()}
                                    onChange={that.startDateChanged}
                                />
                            </div>
                        </div>

                        <div className="col-12"><hr /></div>
                        <div className="col-2 centering"></div>
                        <div className="col-3 centering-v border-bottom"><div>End</div></div>
                        <div className="col-6 centering-v border-bottom">
                            <div style={{ marginLeft: 'auto' }}>
                                <Datetime
                                    inputProps={{
                                        disabled: that.state.form.allDay,
                                        required: true,
                                        className: 'form-control no-border text-align-right'
                                    }}
                                    dateFormat="DD-MMM-YYYY"
                                    timeFormat="hh:mm A"
                                    value={that.state.form.end || moment().add(1, 'hour')}
                                    onChange={that.endDateChanged}
                                />
                            </div>
                        </div>

                        <div className="col-12"><hr /></div>
                        <div className="col-2 centering"><FontAwesomeIcon icon="bell" /></div>
                        <div className="col-3 centering-v border-bottom"><div>Reminder</div></div>
                        <div className="col-6 centering-v border-bottom">
                            <select data-form="reminder" value={that.state.form.reminder || 'none'} onChange={that.formOnChange} className="form-control" style={{ textAlignLast: 'right' }}>
                                <option value="none">None</option>
                                <option value="0">At the time of event</option>
                                <option value="-5 m">5 minutes before</option>
                                <option value="-10 m">10 minutes before</option>
                                <option value="-15 m">15 minutes before</option>
                                <option value="-30 m">30 minutes before</option>
                                <option value="-1 h">1 hour before</option>
                                <option value="-2 h">2 hours before</option>
                                <option value="-1 d">1 day before</option>
                                <option value="-2 d">2 days before</option>
                                <option value="-1 w">1 week before</option>
                            </select>
                        </div>

                        <div className="col-12"><hr /></div>
                        <div className="col-2 centering"><FontAwesomeIcon icon="sticky-note" /></div>
                        <div className="col-9 centering-v border-bottom">
                            <input data-form="description" value={that.state.form.description || ''} onChange={that.formOnChange} className="form-control" type="text" placeholder="Description" />
                        </div>

                        <div className="col-12"><hr /></div>
                        <div className="col-2 centering"><FontAwesomeIcon icon="redo" /></div>
                        <div className="col-3 centering-v border-bottom"><div>Repeat</div></div>
                        <div className="col-6 centering-v border-bottom">
                            <select data-form="repeat" value={that.state.form.repeat || 'never'} onChange={that.formOnChange} className="form-control" style={{ textAlignLast: 'right' }}>
                                <option value="never">Never</option>
                                <option value="day">Every day</option>
                                <option value="week">Every week</option>
                                <option value="month">Every month</option>
                                <option value="year">Every year</option>
                            </select>
                        </div>
                    </div>
                </form>
            </div>
            : '';

        return html;
    }

    static renderMonthTable(that, thisMonth) {
        return (
            <div className={that.state.scope == 'month' ? 'table-responsive table-body show' : 'table-responsive table-body hide'}>
                <table className="table">
                    <thead>
                        <tr>
                            <td>SUN</td>
                            <td>MON</td>
                            <td>TUE</td>
                            <td>WED</td>
                            <td>THU</td>
                            <td>FRI</td>
                            <td>SAT</td>
                        </tr>
                    </thead>
                    {thisMonth}
                </table>
                <div className="calendar-navigators">
                    <div className="btn left"><div className="nav-left" onClick={that.navigatePrevMonth}><FontAwesomeIcon icon="arrow-left" /></div></div>
                    <div className="btn center">
                        <div className="back-to-today" onClick={that.backToToday}><span>{that.state.today.date()}</span></div>
                    </div>
                    <div className="btn right"><div className="nav-right" onClick={that.navigateNextMonth}><FontAwesomeIcon icon="arrow-right" /></div></div>
                </div>
            </div>
        )
    }

    static isYearAndMonthSelected(month, searchYear, currentDate) {
        return 'btn ' + (month.toString() + '/' + searchYear.toString() == currentDate.format('M/YYYY') ? 'selected' : '');
    }

    static renderYearTable(that) {
        return (
            <div className={that.state.scope == 'year' ? 'show' : 'hide'}>
                <div className="year-table-header">
                    <div onClick={that.navigatePrevYear} className="btn navigate-year-left"><FontAwesomeIcon icon="arrow-left" /></div>
                    <div className="btn year-table-year"><span>{that.state.searchYear}</span></div>
                    <div onClick={that.navigateNextYear} className="btn navigate-year-right"><FontAwesomeIcon icon="arrow-right" /></div>
                </div>
                <div className="year-table-content">
                    <div data-month="1" onClick={that.selectMonthOfTheYear} className={Calendar.isYearAndMonthSelected(1, that.state.searchYear, that.state.currentDate)}>January</div>
                    <div data-month="2" onClick={that.selectMonthOfTheYear} className={Calendar.isYearAndMonthSelected(2, that.state.searchYear, that.state.currentDate)}>February</div>
                    <div data-month="3" onClick={that.selectMonthOfTheYear} className={Calendar.isYearAndMonthSelected(3, that.state.searchYear, that.state.currentDate)}>March</div>
                    <div data-month="4" onClick={that.selectMonthOfTheYear} className={Calendar.isYearAndMonthSelected(4, that.state.searchYear, that.state.currentDate)}>April</div>
                    <div data-month="5" onClick={that.selectMonthOfTheYear} className={Calendar.isYearAndMonthSelected(5, that.state.searchYear, that.state.currentDate)}>May</div>
                    <div data-month="6" onClick={that.selectMonthOfTheYear} className={Calendar.isYearAndMonthSelected(6, that.state.searchYear, that.state.currentDate)}>June</div>
                    <div data-month="7" onClick={that.selectMonthOfTheYear} className={Calendar.isYearAndMonthSelected(7, that.state.searchYear, that.state.currentDate)}>July</div>
                    <div data-month="8" onClick={that.selectMonthOfTheYear} className={Calendar.isYearAndMonthSelected(8, that.state.searchYear, that.state.currentDate)}>August</div>
                    <div data-month="9" onClick={that.selectMonthOfTheYear} className={Calendar.isYearAndMonthSelected(9, that.state.searchYear, that.state.currentDate)}>September</div>
                    <div data-month="10" onClick={that.selectMonthOfTheYear} className={Calendar.isYearAndMonthSelected(10, that.state.searchYear, that.state.currentDate)}>October</div>
                    <div data-month="11" onClick={that.selectMonthOfTheYear} className={Calendar.isYearAndMonthSelected(11, that.state.searchYear, that.state.currentDate)}>November</div>
                    <div data-month="12" onClick={that.selectMonthOfTheYear} className={Calendar.isYearAndMonthSelected(12, that.state.searchYear, that.state.currentDate)}>December</div>
                </div>
            </div>
        )
    }

    static renderSetting(that) {
        let html = that.state.settingOpened ? 
            <div className="setting-container">
                <div className="setting-content">
                    <div className="btn close-setting" onClick={that.toggleSetting}>
                        <FontAwesomeIcon icon="times"/>
                    </div>
                    <h3>Setting</h3>
                    <hr/>
                    <div className="form-group">
                        <label>Calendars:</label>
                        <SelectSearch
                            multiple={true}
                            name="SelectedCountryCalendars"
                            className="form-control border-bottom"
                            value={that.state.selectedCountryCalendars}
                            options={that.countryList.map((cal) => { return { value: cal.name, name: cal.name, hide: false } })}
                            onChange={that.countryCalendarChanged}
                            required={false}
                            placeholder="-- Select Calendars --"
                        />
                    </div>
                    <hr />
                    <div className="form-group">
                        <label>Religion Calendars:</label>
                        <SelectSearch
                            multiple={true}
                            name="SelectedReligionCalendars"
                            className="form-control border-bottom"
                            value={that.state.selectedReligionCalendars}
                            options={that.religionList.map((cal) => { return { value: cal.name, name: cal.name, hide: false } })}
                            onChange={that.religionCalendarChanged}
                            required={false}
                            placeholder="-- Select Calendars --"
                        />
                    </div>
                </div>
            </div>
            : '';

        return html;
    }

    render() {
        let thisMonth = this.thisMonth;

        let popover = this.state.popover ?
            <div>
                <div className="popover-popup-overlay" onClick={this.togglePopover}></div>
                <div className="popover-popup">
                    <div onClick={this.toggleSetting}><FontAwesomeIcon icon="cog"/> Setting</div>
                </div>
            </div>: '';

        return (
            <div className="calendar">
                <div>{this.util.render(this.state.notification, this.state.modal)}</div>
                <div className="calendar-content-full-screen">
                    <div className="row">
                        <div className="col-12">
                            <div className="calendar-header">
                                <div className="btn" onClick={this.displaySearchForm}><FontAwesomeIcon icon="search" /></div>
                                {popover}
                                <div className="btn" onClick={this.togglePopover}><FontAwesomeIcon icon="ellipsis-v" /></div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="calendar-content">
                                <div className="centering">
                                    <h3 style={{ cursor: 'pointer' }} onClick={this.toggleOpenBrowseDate}>{this.state.currentDate.format('MMM')} <FontAwesomeIcon icon={this.state.openBrowseDate ? 'caret-up' : 'caret-down'} /></h3>
                                    <div className="current-year">{this.state.currentDate.format('YYYY')}</div>
                                    <div className={this.state.openBrowseDate ? 'navigate-scope show' : 'navigate-scope hide'}>
                                        <div onClick={this.setSearchScope} data-scope="year" className={this.state.scope == 'year' ? 'btn scope-selected' : 'btn'}>Year</div>
                                        <div onClick={this.setSearchScope} data-scope="month" className={this.state.scope == 'month' ? 'btn scope-selected' : 'btn'}>Month</div>
                                    </div>
                                    {Calendar.renderMonthTable(this, thisMonth)}
                                    {Calendar.renderYearTable(this)}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <br />
                            <div className="event-content">
                                <h3>Events</h3>
                                <hr />
                                {Calendar.renderEvents(this)}
                            </div>
                            <div className="btn btn-add-event" onClick={this.displayNewEventForm}>
                                <FontAwesomeIcon icon="plus" />
                            </div>
                            <br />
                            <br />
                        </div>
                    </div>
                </div>
                {Calendar.renderSearchForm(this)}
                {Calendar.renderEventForm(this)}
                {Calendar.renderSetting(this)}
            </div>
        );
    }

    //
}
