import React, { Component } from 'react';
import { Util } from 'helpers/Util';
import { Container } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export class Footer extends Component {
    static displayName = Footer.name;

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="dark">
                <Container className="relative">
                    <div className="row">
                        <div className="col-12 col-md-4 p-top-2 p-bot-2">
                            <h3 className="text-dark">Our Location: </h3>
                            <hr />
                            <p className="text-dark">Office:</p>
                            <p>Jl. Sumber Hurip No.3, Babakan Ciparay, Bandung, West Java, 40223, Indonesia</p>
                            <p className="text-dark">Factory:</p>
                            <p>Jalan Raya Cicalengka - Majalaya KM 6 Kampung Andir RT 28/11, Majalaya, West Java, Indonesia</p>
                        </div>

                        <div className="col-12 col-md-4 p-top-2 p-bot-2">
                            <h3 className="text-dark">Follow Us: </h3>
                            <hr />
                            <span className="social-icon">
                                <a href="https://instagram.com/tba.textile" target="_blank">
                                    <FontAwesomeIcon icon={['fab', 'instagram']} />
                                </a>
                            </span>
                            <span className="social-icon">
                                <a href="https://www.facebook.com/triberkat.anugerah.5" target="_blank">
                                    <FontAwesomeIcon icon={['fab', 'facebook']} />
                                </a>
                            </span>
                            <br /><br /><br /><br />
                            <p className="text-dark" style={{position: 'absolute', bottom: '2rem'}}>
                                Or visit us at <a className="text-light" href="https://id111740900.fm.alibaba.com/company_profile.html?spm=a2700.shop_pl.88.12" target="_blank">Alibaba.com</a>
                            </p>
                        </div>

                        <div className="col-12 col-md-4 p-top-2 p-bot-2">
                            <h3 className="text-dark">Contact Us: </h3>
                            <hr />
                            <p className="text-dark p-top-1">Email: <span><a className="text-light" href="mailto:didy@triberkatanugerah.com">didy@triberkatanugerah.com</a></span></p>
                            <p className="text-dark p-top-1">Phone: <span><a className="text-light" href="tel:62-812-2066-8565">(+62) 812-2066-8565</a></span></p>
                            <p className="text-dark p-top-1">WhatsApp: <span><a className="text-light" href="tel:62-895-3074-3774">(+62) 895-3074-3774</a></span></p>
                        </div>
                    </div>
                </Container>
            </div>
        );
    }
}
