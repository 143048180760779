import React, { Component } from 'react';
import { Util } from 'helpers/Util';
import { Container } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Footer } from "components/Footer";
import { SimpleGallery } from "components/home/Gallery";

export class Home extends Component {
    static displayName = Home.name;

    constructor(props) {
        super(props);
        this.state = Util.injectState(this, {});
    }

    render() {
        return (
            <div className="dark bg-none">
                <div className="absolute h-100-vh w-100 behind">
                    <div className="overlay"></div>
                    <video autoPlay muted loop id="company-video">
                        <source src="/assets/videos/company.mp4" type="video/mp4" />
                        Your browser does not support HTML5 video.
                    </video>
                </div>
                <Container className="shift-by-nav flex h-100-vh text-light" id="top">
                    <div className="flex-center w-100">
                        <h1 className="color-bg">Tri Berkat Anugerah</h1>
                        <h3>We produce the finest yarns and fabrics in Indonesia.</h3>
                        <p>
                            <span className="color-bg">Cotton </span>.
                            <span className="color-bg"> Viscose </span>.
                            <span className="color-bg"> Polyester</span>
                        </p>
                    </div>
                </Container>
                <div className="background-light" id="who-are-we">
                    <Container>
                        <div className="content light p-top-5 p-bot-1">
                            <h3 className="content-title">WHO ARE WE?</h3>
                            <p className="content-subtitle color-bg">Yarn & Fabric Manufacturer</p>
                            <p className="content-desc">PT. Tri Berkat Anugerah is a company engaged in the field of textiles which has 2 business lines, namely in the field of spinning and knitting. Spinning is the process of making cotton into yarn, Knitting is the process of knitting yarn into fabric.PT. Tri Berkat Anugerah is located at Jalan Raya Cicalengka - Majalaya KM 6 Kampung Andir RT 28/11, Majalaya, West Java. This company has been established since 2010 which was originally just a trading company. Thanks to the hard work and perseverance of the owner, Mr. Lim Ping Luan (Didi), his business can develop into a fairly large company. This company produces yarn or basic fabric which will be dyed and processed back into garments to become clothes.</p>
                        </div>
                    </Container>
                </div>
                <div className="background-light" id="products">
                    <Container>
                        <div className="content light p-top-5 p-bot-1">
                            <h3 className="content-title text-center">OUR PRODUCTS</h3>
                            <p className="content-subtitle color-bg"></p>
                            <div className="row" style={{ paddingTop: '2rem' }}>
                                <div className="col-12 col-md-6 p-top-1">
                                    <div>
                                        <img className="thumbnail" src="/assets/images/yarn.jpg" />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 p-top-1">
                                    <h3>Yarns</h3>
                                    <p className="content-subtitle">
                                        <span className="color-bg">Cotton </span>.
                                        <span className="color-bg"> Viscose </span>.
                                        <span className="color-bg"> Polyester</span>
                                    </p>
                                    <div>
                                        <p>NE Count: 20 - 40</p>
                                        <p>Packing: PP Bag / Carton</p>
                                        <p>Technic: Ring Spun</p>
                                        <p>Usage: Knitting & Weaving</p>
                                        <p>Supply Ability: 190 Tons / Month</p>
                                        <p>Color: Raw White</p>
                                        <p>OEM & ODM: Acceptable</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row" style={{ paddingTop: '2rem' }}>
                                <div className="col-12 col-md-6 p-top-1">
                                    <div>
                                        <img className="thumbnail" src="/assets/images/fabric.jpg" />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 p-top-1">
                                    <h3>Fabrics</h3>
                                    <p className="content-subtitle">
                                        <span className="color-bg">Cotton </span>.
                                        <span className="color-bg"> Viscose </span>.
                                        <span className="color-bg"> Polyester</span>
                                    </p>
                                    <div>
                                        <p>NE Count: 20 - 40</p>
                                        <p>Yarn Type: Knitting Combed & Carded</p>
                                        <p>Pattern: Plain Dyed As Requested</p>
                                        <p>Usage: Dress, Garment, Shirt, Sportswear, Sleepwear</p>
                                        <p>Supply Ability: 150 Tons / Month</p>
                                        <p>Weight & Width: As Requested</p>
                                        <p>OEM & ODM: Acceptable</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Container>
                </div>
                <div className="background-light" id="gallery">
                    <Container>
                        <div className="content light p-bot-1">
                            <h3 className="text-center">Gallery</h3>
                            <p className="text-center p-bot-2 content-subtitle color-bg">Our factory images</p>
                            <SimpleGallery />
                        </div>
                    </Container>
                </div>
                <div className="background-light" id="location">
                    <Container>
                        <div className="content light p-top-3 p-bot-1">
                            <h3 className="text-center">Office Location</h3>
                            <p className="text-center content-subtitle color-bg">Feel free to visit our office. <br/>
                            We are open from Monday to Friday 8AM - 5PM.</p>

                            <div className="flex">
                                <div className="mapouter flex-center">
                                    <div className="gmap_canvas">
                                        <iframe id="gmap_canvas" src="https://maps.google.com/maps?q=Sumber%20Hurip%20No%203&t=&z=15&ie=UTF8&iwloc=&output=embed" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0">
                                        </iframe>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Container>
                </div>
                <Footer />
            </div>
        );
    }
}