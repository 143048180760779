import React, { Component } from 'react';
import { Http } from 'helpers/Http';
import { Util } from 'helpers/Util';

export class ConfirmEmail extends Component {
    static displayName = ConfirmEmail.name;

    constructor(props) {
        super(props);
        this.state = Util.injectState(this, {
            confirmResult: null,
            confirmMessage: null,
        });
        this.util = new Util(this);
        Http.init(this);
    }

    async componentDidMount() {
        let result = await Http.postData(null, { ConfirmationHash: this.state.params.hash }, '/account/confirmEmail');
        this.setState({ confirmResult: result.success, confirmMessage: result.msg });
        if (result.success) {
            setTimeout(function () {
                Util.history.push('/');
            }.bind(this), 3000);
        }
    }

    render() {
        let msg = this.state.confirmResult == null ? <h3>Confirming Your Email Please Wait...</h3> :
            this.state.confirmResult ? <h3 className="text-success">{this.state.confirmMessage}</h3> : 
                <h3 className="text-danger">{this.state.confirmMessage}</h3>;

        return (
            <div>
                <div>{this.util.render(this.state.notification, this.state.modal)}</div>
                <div className="middle-vertical">
                    <div>
                        {msg}
                    </div>
                </div>
            </div>
        );
    }
}
