import React, { Component } from 'react';
import { Http } from 'helpers/Http';
import { Util } from 'helpers/Util';

export class FetchData extends Component {
    static displayName = FetchData.name;

    constructor(props) {
        super(props);
        this.state = Util.injectState(this, { forecasts: [] });
        this.util = new Util(this);
        Http.init(this);
    }

    componentDidMount() {
        this.populateWeatherData();
    }

    async populateWeatherData() {
        let data = await Http.getData(null, 'WeatherForecast');
        data = data == null ? [] : data;
        this.setState({ forecasts: data, loading: false });
    }

    renderForecastsTable(forecasts, loading) {
        let loader = this.util.renderLoading(loading);
        let theme = localStorage.getItem('theme');

        let body = loading ? null :
            <tbody>
                {forecasts.map(forecast =>
                    <tr key={forecast.Date}>
                        <td>{Util.displayDateTime12Hour(forecast.Date)}</td>
                        <td>{Util.displayNumber(forecast.TemperatureC)}</td>
                        <td>{Util.displayDecimal2(forecast.TemperatureF)}</td>
                        <td>{forecast.Summary}</td>
                    </tr>
                )}
            </tbody>

        return (
            <div>
                <table className={theme == 'dark' ? 'table table-striped table-dark' : 'table table-striped'} aria-labelledby="tabelLabel">
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Temp. (C)</th>
                            <th>Temp. (F)</th>
                            <th>Summary</th>
                        </tr>
                    </thead>
                    {body}
                </table>
                <div>{loader}</div>
            </div>
        );
    }

    render() {
        return (
            <div>
                <div>{this.util.render(this.state.notification, this.state.modal)}</div>
                <h1 id="tabelLabel" >Weather forecast</h1>
                <p>This component demonstrates fetching data from the server.</p>
                <div>{this.renderForecastsTable(this.state.forecasts, this.state.loading)}</div>
            </div>
        );
    }
}
